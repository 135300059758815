import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Breadcrumbs,
  Link,
  Divider,
} from "@mui/material";
import {
  Add,
  Close,
  FilterAlt,
  OutboxOutlined,
  Search,
} from "@mui/icons-material";
import { colors } from "../../constants/colors";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import MFTable from "../../components/MFTable/MFTable";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import dropdown from "../../assets/icons/dropdown.svg";
import AssetTable from "../../components/AssetTable/AssetHoldingTable";
import AifTable from "../../components/AssetTable/AIF";
import UploadModal from "../../components/modals/UploadDataModal";
import TableComponent from "../../components/Tables/TableRow";
import styles from "./AssetHolding.module.css";
import api from "../../config/apiConfig";
import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import MFAssetTable from "../../components/AssetHoldingsTables/MFAssetTable";
import AIFAssetTable from "../../components/AssetHoldingsTables/AIFAssetTable";
import BONDAssetTable from "../../components/AssetHoldingsTables/BONDAssetTable";
import EquityAssetTable from "../../components/AssetHoldingsTables/EquityAssetTable";
import FDAssetTable from "../../components/AssetHoldingsTables/FDAssetTable";
import PMSAssetTable from "../../components/AssetHoldingsTables/PMSAssetTable";
import {
  addSingleHoldingsData,
  FDAHInfo,
  getUnrealisedMFInfo,
  HoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import { useDispatch } from "react-redux";
import { useUserData } from "../../hooks/useUserData";
import AutoImportFromGmail from "../../components/modals/AutoImportFromGmail";
import PPFAssetTable from "../../components/AssetHoldingsTables/PPFAssetTable";
import PrivateEquityAssetTable from "../../components/AssetHoldingsTables/PrivateEquityAssetTable";
import useWebSocket from "../../hooks/useWebSocket";
import AddHoldingsModalForm from "../../components/modals/AddHoldingsModalForm";
import SuccessfullModal from "../../components/modals/SuccessfullModal";

const filterOps: any = [
  {
    name: "Asset",
    options: [
      {
        name: "Mutual Fund",
        value: 1,
        isChecked: false,
      },
      {
        name: "Corporate FD",
        value: 3,
        isChecked: false,
      },
      {
        name: "PMS/AIF",
        value: 5,
        isChecked: false,
      },
      {
        name: "Equity",
        value: 7,
        isChecked: false,
      },
      {
        name: "Bond",
        value: 8,
        isChecked: false,
      },
      {
        name: "SGB/FRB",
        value: 11,
        isChecked: false,
      },
      {
        name: "Others",
        value: 11,
        isChecked: false,
      },
    ],
  },
  {
    name: "Company",
    options: [
      {
        name: "Online",
        value: "online",
        isChecked: false,
      },
      {
        name: "Offline",
        value: "offline",
        isChecked: false,
      },
    ],
  },
];

const options = [
  { value: "Mutual Fund", label: "Mutual Fund" },
  { value: "STOCK", label: "Equity" },
  { value: "BOND", label: "Bond" },
  { value: "PMS", label: "PMS" },
  { value: "AIF", label: "AIF" },
  { value: "Fixed Deposit", label: "Fixed Deposit" },
  { value: "PPF", label: "PPF/EPS/KWP" },
  { value: "PRIVATE_STOCK", label: "Unlisted Equity" },
];

const AssetHolding = () => {
  const dispatch = useDispatch<any>();
  const user = useUserData();
  const [MFBodyData, setMFBodyData] = useState([]);
  const [lastDate, setLastDate] = useState([]);
  const [AIFBodyData, setAIFBodyData] = useState([]);
  const [totalFound, setTotalFound] = useState("07");
  const [totalFound1, setTotalFound1] = useState("04");
  const [filterOptions, setFilterOptions] = useState(filterOps);
  const [checked, setChecked] = React.useState([true, false]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const [equityButtonVisible, setEquityButtonVisible] = useState(false);
  const [debtButtonVisible, setDebtButtonVisible] = useState(false);
  const [hybridButtonVisible, setHybridButtonVisible] = useState(false);
  const [solutionButtonVisible, setSolutionButtonVisible] = useState(false);
  const [othersButtonVisible, setOthersButtonVisible] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);

  const { connectWebSocket, disconnectWebSocket } = useWebSocket();
  const [FD, setFD] = useState([]);
  const [AIF, setAIF] = useState([]);
  const [PMS, setPMS] = useState([]);
  const [BOND, setBOND] = useState([]);
  const [STOCK, setSTOCK] = useState([]);
  const [PPF, setPPF] = useState([]);
  const [privateStock, setPrivateStock] = useState([]);

  const [MFCheck, setMFCheck] = useState(false);
  const [FDCheck, setFDCheck] = useState(false);
  const [AIFCheck, setAIFCheck] = useState(false);
  const [PMSCheck, setPMSCheck] = useState(false);
  const [BONDCheck, setBONDCheck] = useState(false);
  const [STOCKCheck, setSTOCKCheck] = useState(false);
  const [PPFCheck, setPPFCheck] = useState(false);
  const [PrivateCheck, setPrivateCheck] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]); // Track multiple selected options
  const [isUploadClicked, setIsUploadClicked] = useState(false);

  const [isModalOpen, setISModalOpen] = useState(false);
  const [fields, setFields] = useState<any>([]);
  const [holdingType, setHoldingType] = useState("");
  const [title, setTitle] = useState("");
  const [Open, setOpen] = useState(false);
  const [Message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleSelection = (value: string) => {
    if (value === "Mutual Fund") {
      setMFCheck(!MFCheck);
    } else if (value === "Fixed Deposit") {
      setFDCheck(!FDCheck);
    } else if (value === "AIF") {
      setAIFCheck(!AIFCheck);
    } else if (value === "PMS") {
      setPMSCheck(!PMSCheck);
    } else if (value === "BOND") {
      setBONDCheck(!BONDCheck);
    } else if (value === "STOCK") {
      setSTOCKCheck(!STOCKCheck);
    } else if (value === "PPF") {
      setPPFCheck(!PPFCheck);
    } else {
      setPrivateCheck(!PrivateCheck);
    }

    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    ); // Toggle selection
  };

  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl2(null);
  };

  const handleMenuItemClick = async (id: number, name: string) => {
    setSelectedMember(name);
    setAnchorEl2(null);
    fetchDataMF(id);
    fetchDataFD(id);
    fetchDataAIF(id);
    fetchDataPMS(id);
    fetchDataBOND(id);
    fetchDataSTOCK(id);
    fetchDataPrivateStock(id);
    fetchDataPPF(id);
  };

  const handleMouseEnter = () => {
    setShowScrollbar(true);
  };

  const handleMouseLeave = () => {
    setShowScrollbar(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleOpenImportModal = () => {
    setImportOpen(true);
    connectWebSocket();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    fetchDataMF("");
    fetchDataFD("");
    fetchDataAIF("");
    fetchDataPMS("");
    fetchDataBOND("");
    fetchDataSTOCK("");
    fetchDataPPF("");
    fetchDataPrivateStock("");
  };

  const handleCloseImportModal = () => {
    setImportOpen(false);
    disconnectWebSocket();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setISModalOpen(false);
  };

  const handleChange1 = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number
  ) => {
    const { checked } = event.target;

    // Update the isChecked property of each option
    const updatedOptions = filterOptions.map(
      (filter: { options: any[] }, index: number) => {
        if (index === filterIndex) {
          // If it's the target filter, update its options
          return {
            ...filter,
            options: filter.options.map((option: any) => ({
              ...option,
              isChecked: checked,
            })),
          };
        }
        return filter;
      }
    );

    // Check if at least one option is checked in the current filter
    const isAnyOptionChecked = updatedOptions[filterIndex].options.some(
      (option: any) => option.isChecked
    );

    // Update the visibility state of the buttons based on the filter index
    switch (filterIndex) {
      case 0: // Equity
        setEquityButtonVisible(isAnyOptionChecked);
        break;
      case 1: // Debt
        setDebtButtonVisible(isAnyOptionChecked);
        break;
      case 2: // Debt
        setHybridButtonVisible(isAnyOptionChecked);
        break;
      case 3: // Debt
        setSolutionButtonVisible(isAnyOptionChecked);
        break;
      case 4: // Debt
        setOthersButtonVisible(isAnyOptionChecked);
        break;
      // Add cases for other filters as needed
      default:
        break;
    }

    // Update the state with the modified options
    setFilterOptions(updatedOptions);
  };

  const handleChange2 = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number,
    optionIndex: number
  ) => {
    const { checked } = event.target;
  };
  const [hoveredButton, setHoveredButton] = useState<number | null>(null);

  const fetchDataMF = async (userId: any) => {
    try {
      const response = await dispatch(
        getUnrealisedMFInfo({
          userId: userId || "",
          startDate: 1,
          endDate: 1,
          fundType: "",
          transactionType: 1,
          fetchFor: "consolidated", // aumcapital or consolidated
        })
      );

      if (response?.payload) {
        setMFBodyData(response?.payload.data);
        setLastDate(response?.payload?.lastImportDate);
        console.log(response?.payload.data, "MF");
      } else {
        console.log(response);
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const fetchDataFD = async (userId: any) => {
    try {
      const response = await dispatch(
        FDAHInfo({
          userId: userId || "",
          startDate: 0,
          endDate: 0,
          forRm: 0,
        })
      );

      if (response?.payload) {
        setFD(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataAIF = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "AIF" })
      );

      if (response?.payload) {
        setAIF(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataPMS = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "PMS" })
      );

      if (response?.payload) {
        setPMS(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataBOND = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "BOND" })
      );

      if (response?.payload) {
        setBOND(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataSTOCK = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "STOCK" })
      );

      if (response?.payload) {
        setSTOCK(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataPPF = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "PPF" })
      );
      if (response?.payload) {
        setPPF(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataPrivateStock = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "PRIVATE_STOCK" })
      );

      if (response?.payload) {
        setPrivateStock(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fdLength = FD?.length || 0;
  const bondLength = BOND?.length || 0;
  const MFLength = MFBodyData?.length || 0;
  const aifLength = AIF?.length || 0;
  const pmsLength = PMS?.length || 0;
  const stockLength = STOCK?.length || 0;
  const ppfLength = PPF?.length || 0;
  const privateStockLength = privateStock?.length || 0;
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Investment", href: "/" }, // Breadcrumb item with link
    { label: "Asset Holdings" }, // Current page, no link
  ];

  useEffect(() => {
    setMFCheck(!MFCheck);
    setSelectedOptions(["Mutual Fund"]);
    fetchDataMF("");
    fetchDataFD("");
    fetchDataAIF("");
    fetchDataPMS("");
    fetchDataBOND("");
    fetchDataSTOCK("");
    fetchDataPPF("");
    fetchDataPrivateStock("");
  }, []);

  const handleModalOpen = () => setModalOpen(true);

  const handleSetValues = (type: string) => {
    if(type === "MF"){
      setTitle(`${type} Transaction`);
      setHoldingType(type);
      setFields([
        { name: "assetName", label: "MF Name", type: "string" },
        { name: "dateOfInvestment", label: "Date Of Investment", type: "date" },
        { name: "investmentAmount", label: "Investment Amount", type: "number" },
        { name: "purchaseNav", label: "Purchase NAV", type: "number" },
        // { name: "currentNavDate", label: "Current NAV Date", type: "date" },
        { name: "units", label: "Units", type: "number" },
        // { name: "currentNav", label: "Current NAV", type: "number" },
        // { name: "currentValue", label: "Current Value", type: "number" },
        // {
        //   name: "isin",
        //   label: "ISIN",
        //   type: "string",
        // },
        // {
        //   name: "amcCode",
        //   label: "AMC Code",
        //   type: "string",
        // },
        { name: "folioNumber", label: "Folio Number", type: "number" },
      ]);
    }else if (type === "PMS") {
      setTitle(`${type} Transaction`);
      setHoldingType("PMS");
      setFields([
        { name: "assetName", label: "PMS Name", type: "string" },
        { name: "folioNumber", label: "Folio Number", type: "number" },
        { name: "purchaseDate", label: "Purchase Date", type: "date" },
        { name: "amount", label: "Amount", type: "number" },
      ]);
    } else if (type === "AIF") {
      setTitle(`${type} Transaction`);
      setHoldingType(type);
      setFields([
        { name: "assetName", label: "AIF Name", type: "string" },
        { name: "purchaseDate", label: "Purchase Date", type: "date" },
        { name: "amount", label: "Amount", type: "number" },
        { name: "currentNAV", label: "Current NAV", type: "number" },
      ]);
    } else if (type === "FD") {
      setTitle(`${type} Transaction`);
      setHoldingType(type);
      setFields([
        { name: "assetName", label: "FD Name", type: "string" },
        { name: "folioNumber", label: "Folio Number", type: "number" },
        { name: "purchaseDate", label: "Purchase Date", type: "date" },
        { name: "amount", label: "Amount", type: "number" },
        { name: "tenure", label: "Tenure", type: "number" },
        { name: "maturityDate", label: "Maturity Date", type: "date" },
        { name: "interest", label: "Interest Gain", type: "number" },
        // { name: "currentInterest", label: "Current Interest", type: "number" },
        {
          name: "option",
          label: "Duration (monthly/quaterly/halfyearly/annual)",
          type: "string",
        },
        { name: "roi", label: "Rate Of Interest", type: "number" },
      ]);
    } else if (type === "BOND") {
      setTitle(`${type} Transaction`);
      setHoldingType(type);
      setFields([
        { name: "assetName", label: "Bond Name", type: "string" },
        { name: "quantity", label: "Quantity", type: "number" },
        { name: "price", label: "Price", type: "number" },
        {
          name: "demetAccountNumber",
          label: "Demat Account Number",
          type: "string",
        },
        {
          name: "settlementAmount",
          label: "Settlement Amount",
          type: "number",
        },
        { name: "settlementDate", label: "Settlement Date", type: "date" },
        { name: "yield", label: "Yield", type: "number" },
        { name: "source", label: "Source", type: "string" },
      ]);
    } else if (type === "PPF/EPS/KWP") {
      setTitle(`${type} Transaction`);
      setHoldingType(type);
      setFields([
        { name: "schemeName", label:"PPF Name", type: "string" },
        { name: "interestRate", label: "Interest Rate", type: "number" },
        // { name: "purchaseDate", label: "Purchase Date", type: "date" },
        { name: "maturityDate", label: "Maturity Date", type: "date" },
        { name: "lockInPeriod", label: "Lock In Period", type: "number" },
        { name: "uanNo", label: "UAN No", type: "string" },
        { name: "amount", label: "Amount", type: "number" },
      ]);
    } else if (type === "STOCK") {
      setTitle(`Equity Transaction`);
      setHoldingType(type);
      setFields([
        { name: "assetName", label: "Equity Name", type: "string" },
        {
          name: "transType",
          label: "Transaction Type (Buy/Sell) ",
          type: "string",
        },
        { name: "amount", label: "Amount", type: "number" },
        { name: "interestRate", label: "Purchase Price", type: "number" },
      ]);
    } else {
      setTitle(`Unlisted Equity Transaction`);
      setHoldingType(type);
      setFields([
        { name: "assetName", label: "Equity Name", type: "string" },
        {
          name: "transType",
          label: "Transaction Type (Buy/Sell) ",
          type: "string",
        },
        { name: "amount", label: "Amount", type: "number" },
        { name: "interestRate", label: "Purchase Price", type: "number" },
      ]);
    }
    setISModalOpen(true);
  };

  const formatDate = (dateString: any): string => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    let payload;
    if (holdingType === "MF") {
      const formatter = new Intl.DateTimeFormat("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const today = formatter.format(new Date());
      payload = {
        userId: user?.id,
        holdingType: 2,
        scheme: formData.assetName,
        date_of_investment: formatDate(formData.dateOfInvestment),
        amount: formData.investmentAmount,
        purchase_nav: formData.purchaseNav,
        units: formData.units,
        current_nav: formData.currentNAV,
        current_nav_date: today,
        current_value: Number(formData.currentNAV) * Number(formData.units),
        isin: formData.isin,
        category: formData.category,
        amc_code: formData.amcCode,
        folio_no: formData.folioNumber,
        purchaseDate: "123456",
      };
    } else if (holdingType === "PMS") {
      payload = {
        userId: user?.id,
        holdingType: 6,
        assetName: formData.assetName,
        category: formData.category,
        purchaseDate: formatDate(formData.purchaseDate),
        amount: formData.amount,
        folioNumber: formData.folioNumber,
      };
    } else if (holdingType === "FD") {
      payload = {
        userId: user?.id,
        holdingType: 3,
        assetName: formData.assetName,
        category: "-",
        folioNo: formData.folioNumber,
        purchaseDate: formatDate(formData.purchaseDate),
        amount: formData.amount,
        tenure: formData.tenure,
        maturityDate: formatDate(formData.maturityDate),
        interest: formData.interest,
        currentInterest: 0,
        option: formData.option,
        roi: (Number(formData.roi)/100).toFixed(4),
      };
    } else if (holdingType === "AIF") {
      payload = {
        userId: user?.id,
        holdingType: 5,
        assetName: formData.assetName,
        category: formData.category,
        purchaseDate: formatDate(formData.purchaseDate),
        amount: formData.amount,
        currentNAV: formData.currentNAV,
      };
    } else if (holdingType === "PPF/EPS/KWP") {
      payload = {
        userId: user?.id,
        holdingType: 7,
        assetName: formData.schemeName,
        category: "-",
        interestRate: formData.interestRate,
        purchaseDate: "123456",
        maturityDate: formatDate(formData.maturityDate),
        lockInPeriod: formData.lockInPeriod,
        uanNo: formData.uanNo,
        amount: formData.amount,
      };
    } else if (holdingType === "BOND") {
      payload = {
        userId: user?.id,
        holdingType: 1,
        isin: formData.assetName,
        category: formData.bondCategory,
        quantity: formData.quantity,
        demat_account_number: formData.dematAccountNumber,
        settlement_date: formatDate(formData.settlementDate),
        amount: formData.price,
        settlement_amount: formData.settlementAmount,
        yield: formData.yield,
        source: formData.source,
        purchaseDate: "123456",
      };
    } else if (holdingType === "STOCK") {
      payload = {
        userId: user?.id,
        holdingType: 4,
        asset_name: formData.stockSymbol,
        category: "-",
        purchaseDate: "123456",
        trans_type: formData.transType,
        rate: formData.interestRate,
        amount: formData.amount,
      };
    } else {
      payload = {
        userId: user?.id,
        holdingType: 8,
        asset_name: formData.assetName,
        purchaseDate: "123456",
        category: "-",
        trans_type: formData.transType,
        rate: formData.interestRate,
        amount: formData.amount,
      };
    }

    try {
      setLoading(true);
      const response = await dispatch(addSingleHoldingsData(payload));
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`Scheme edided successfully.`);
        if(holdingType==="MF"){
          fetchDataMF("");
        }else if (holdingType === "PMS") {
          fetchDataPMS("");
        } else if (holdingType === "AIF") {
          fetchDataAIF("");
        } else if (holdingType === "BOND") {
          fetchDataBOND("");
        } else if (holdingType === "STOCK") {
          fetchDataSTOCK("");
        } else if (holdingType === "PPF/EPS/KWP") {
          fetchDataPPF("");
        } else if (holdingType === "FD") {
          fetchDataFD("");
        } else {
          fetchDataPrivateStock("");
        }
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleSuccessClose = () => {
    setOpen(false);
    setMessage("");
  };

  return (
    <>
      <div>
        <div className="bg-lightBg">
          <div className="p-8 md:p-[40px] max-w-[1440px] w-full mx-auto flex flex-col gap-y-2">
            <DynamicBreadcrumbs items={breadcrumbItems} />
            <Divider sx={{ my: 2 }} />{" "}
            {/* Adds a division between the breadcrumbs and the content below */}
            <div className="flex flex-row gap-x-2 items-center w-full pb-4 justify-between">
              <div>
                <h1 className="text-3xl font-medium text-gray-900 w-[30vw]">
                  Asset Holdings
                  {selectedMember === "" ? "" : ", " + selectedMember}
                </h1>
              </div>
              {/* <div className="flex-grow"></div>{" "} */}
              {/* This ensures the button is pushed to the right */}
              <div className="flex justify-end w-full">
                {(selectedMember === "" ||
                  selectedMember === user?.firstName) && (
                  <button
                    className="bg-primary text-white py-2 px-6 rounded hover:bg-white hover:text-primary border border-blue-900 "
                    onClick={handleOpenModal}
                  >
                    Upload Data
                  </button>
                )}

                <button
                  className="bg-primary text-white py-2 px-6 rounded hover:bg-white hover:text-primary border border-blue-900 ml-[10px]"
                  onClick={handleOpenImportModal}
                >
                  Auto Import From Mail
                </button>

                {user?.familyMembers?.length > 0 && (
                  <Button
                    variant="outlined"
                    endIcon={<ArrowDropDownIcon />}
                    sx={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      fontWeight: 500,
                      textTransform: "none",
                      display: "flex",
                      padding: "6px 10px",
                      borderRadius: "4px",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid blue",
                      borderColor: "lightGrey",
                      gap: "2px",
                      backgroundColor: "rgb(37, 82, 136)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "rgb(37, 82, 136)",
                      },
                    }}
                    onClick={handleDropdownClick}
                  >
                    {selectedMember || "Family Members"}
                  </Button>
                )}
                <Menu
                  anchorEl={anchorEl2}
                  open={Boolean(anchorEl2)}
                  disableScrollLock={true}
                  onClose={handleMenuClose}
                  sx={{
                    "& .MuiPaper-root": {
                      minWidth: anchorEl2?.offsetWidth || 0, // Match the width of the button
                    },
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick(user?.id, user?.firstName)
                    }
                    sx={{
                      width: "100%", // Ensure menu items take full width of the menu
                    }}
                  >
                    {user?.firstName}
                  </MenuItem>
                  {user?.familyMembers?.map((member: any) => (
                    <MenuItem
                      key={member.id}
                      onClick={() =>
                        handleMenuItemClick(member.id, member.firstName)
                      }
                      sx={{
                        width: "100%", // Ensure menu items take full width of the menu
                      }}
                    >
                      {member.firstName}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </div>
          <UploadModal open={modalOpen} handleClose={handleCloseModal} />
          <AutoImportFromGmail
            open={importOpen}
            handleClose={handleCloseImportModal}
          />
        </div>
        <div className="px-8 py-6 max-w-[1440px] w-full mx-auto">
          <Box display="flex" gap={2} flexWrap="wrap">
            {options.map((item) => (
              <Button
                key={item.value}
                value={item.value}
                onClick={() => toggleSelection(item.value)}
                sx={{
                  color: selectedOptions.includes(item.value)
                    ? colors.white
                    : colors.textGrey,
                  backgroundColor: selectedOptions.includes(item.value)
                    ? colors.primary
                    : "transparent",
                  border: `1px solid ${colors.darkGrey}`,
                  "&:hover": {
                    backgroundColor: colors.mediumBg,
                  },
                  fontSize: {
                    xs: "10px",
                    sm: "15px",
                  },
                  textTransform: "none",
                  transition: "all 0.3s ease", // Smooth transition for hover and state changes
                  padding: "6px 12px",
                  borderRadius: 20,
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </div>
        {MFCheck ||
        FDCheck ||
        AIFCheck ||
        PMSCheck ||
        BONDCheck ||
        STOCKCheck ||
        PPFCheck ||
        PrivateCheck ? (
          <div className="px-8 py-4 max-w-[1440px] w-full mx-auto">
            {MFCheck && (
              <div className="">
                <div className="flex flex-col ">
                  <div className="flex gap-x-8 items-center">
                    <div className="flex flex-row w-full justify-start pb-4">
                      <div>
                        <h1 className="text-2xl font-semibold text-primary">
                          Mutual Funds
                        </h1>
                      </div>
                      <div className="pl-2">
                        <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                          {MFLength}
                        </div>
                      </div>
                    </div>
                    {user?.userType === 5 && (
                      <div className="w-[200px] flex justify-end items-start">
                        <button
                          className="text-white bg-primary hover:bg-secondary px-4 py-2 cursor-pointer rounded"
                          onClick={() => {
                            handleSetValues("MF");
                          }}
                        >
                          Add MF
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  // className={`${styles.overflowYAuto} ${styles.maxH96} ${
                  //   showScrollbar ? styles.showScrollbar : ""
                  // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
                  //   styles.scrollbarTrackPrimary
                  // }`}
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  className="mb-16"
                >
                  {MFBodyData && MFBodyData?.length > 0 ? (
                    <MFAssetTable data={MFBodyData} lastDate={lastDate} />
                  ) : (
                    <div className="w-full text-center text-darkGrey my-6 text-xl font-semibold">
                      No data is available for this asset type.
                    </div>
                  )}
                </div>
              </div>
            )}

            {STOCKCheck && (
              <div className="mb-16">
                <div className="flex flex-col ">
                  <div className="flex gap-x-8 items-center justify-between">
                    <div className="flex flex-row w-full justify-start pb-4">
                      <div>
                        <h1 className="text-2xl font-semibold text-primary">
                          Equity
                        </h1>
                      </div>
                      <div className="pl-2">
                        <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                          {stockLength}
                        </div>
                      </div>
                    </div>
                    <div className="w-[200px] flex justify-end items-start">
                      <button
                        className="text-white bg-primary hover:bg-secondary px-4 py-2 cursor-pointer rounded"
                        onClick={() => {
                          handleSetValues("STOCK");
                        }}
                      >
                        Add Equity
                      </button>
                    </div>
                  </div>
                </div>
                <div
                // className={`${styles.overflowYAuto} ${styles.maxH96} ${
                //   showScrollbar ? styles.showScrollbar : ""
                // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
                //   styles.scrollbarTrackPrimary
                // }`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  {STOCK && STOCK?.length > 0 ? (
                    <EquityAssetTable
                      data={STOCK}
                      fetchDataSTOCK={fetchDataSTOCK}
                    />
                  ) : (
                    <div className="w-full text-center text-darkGrey my-6 text-xl font-semibold">
                      No data is available for this asset type.
                    </div>
                  )}
                </div>
              </div>
            )}
            {BONDCheck && (
              <div className="mb-16">
                <div className="flex flex-col ">
                  <div className="flex gap-x-8 items-center justify-between">
                    <div className="flex flex-row w-full justify-start pb-4">
                      <div>
                        <h1 className="text-2xl font-semibold text-primary">
                          Bonds
                        </h1>
                      </div>
                      <div className="pl-2">
                        <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                          {bondLength}
                        </div>
                      </div>
                    </div>
                    <div className="w-[200px] flex justify-end items-start">
                      <button
                        className="text-white bg-primary hover:bg-secondary px-4 py-2 cursor-pointer rounded"
                        onClick={() => {
                          handleSetValues("BOND");
                        }}
                      >
                        Add Bond
                      </button>
                    </div>
                  </div>
                </div>
                <div
                // className={`${styles.overflowYAuto} ${styles.maxH96} ${
                //   showScrollbar ? styles.showScrollbar : ""
                // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
                //   styles.scrollbarTrackPrimary
                // }`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  {BOND && BOND?.length > 0 ? (
                    <BONDAssetTable data={BOND} fetchDataBOND={fetchDataBOND} />
                  ) : (
                    <div className="w-full text-center text-darkGrey my-6 text-xl font-semibold">
                      No data is available for this asset type.
                    </div>
                  )}
                </div>
              </div>
            )}

            {PMSCheck && (
              <div className="mb-16">
                <div className="flex flex-col ">
                  <div className="flex gap-x-8 items-center justify-between">
                    <div className="flex flex-row w-full justify-start pb-4">
                      <div>
                        <h1 className="text-2xl font-semibold text-primary">
                          PMS
                        </h1>
                      </div>
                      <div className="pl-2">
                        <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                          {pmsLength}
                        </div>
                      </div>
                    </div>
                    <div className="w-[200px] flex justify-end items-start">
                      <button
                        className="text-white bg-primary hover:bg-secondary px-4 py-2 cursor-pointer rounded"
                        onClick={() => {
                          handleSetValues("PMS");
                        }}
                      >
                        Add PMS
                      </button>
                    </div>
                  </div>
                </div>
                <div
                // className={`${styles.overflowYAuto} ${styles.maxH96} ${
                //   showScrollbar ? styles.showScrollbar : ""
                // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
                //   styles.scrollbarTrackPrimary
                // }`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  {PMS && PMS?.length > 0 ? (
                    <PMSAssetTable data={PMS} fetchDataPMS={fetchDataPMS} />
                  ) : (
                    <div className="w-full text-center text-darkGrey my-6 text-xl font-semibold">
                      No data is available for this asset type.
                    </div>
                  )}
                </div>
              </div>
            )}

            {AIFCheck && (
              <div className="mb-16">
                <div className="flex flex-col ">
                  <div className="flex gap-x-8 items-center justify-between">
                    <div className="flex flex-row w-full justify-start pb-4">
                      <div>
                        <h1 className="text-2xl font-semibold text-primary">
                          AIF
                        </h1>
                      </div>
                      <div className="pl-2">
                        <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                          {aifLength}
                        </div>
                      </div>
                    </div>
                    <div className="w-[200px] flex justify-end items-start">
                      <button
                        className="text-white bg-primary hover:bg-secondary px-4 py-2 cursor-pointer rounded"
                        onClick={() => {
                          handleSetValues("AIF");
                        }}
                      >
                        Add AIF
                      </button>
                    </div>
                  </div>
                </div>
                <div
                // className={`${styles.overflowYAuto} ${styles.maxH96} ${
                //   showScrollbar ? styles.showScrollbar : ""
                // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
                //   styles.scrollbarTrackPrimary
                // }`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  {AIF && AIF?.length > 0 ? (
                    <AIFAssetTable data={AIF} fetchDataAIF={fetchDataAIF} />
                  ) : (
                    <div className="w-full text-center text-darkGrey my-6 text-xl font-semibold">
                      No data is available for this asset type.
                    </div>
                  )}
                </div>
              </div>
            )}

            {FDCheck && (
              <div className="mb-16">
                <div className="flex flex-col ">
                  <div className="flex gap-x-8 items-center justify-between">
                    <div className="flex flex-row w-full justify-start pb-4">
                      <div>
                        <h1 className="text-2xl font-semibold text-primary">
                          Fixed Deposit
                        </h1>
                      </div>
                      <div className="pl-2">
                        <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                          {fdLength}
                        </div>
                      </div>
                    </div>
                    <div className="w-[200px] flex justify-end items-start">
                      <button
                        className="text-white bg-primary hover:bg-secondary px-4 py-2 cursor-pointer rounded"
                        onClick={() => {
                          handleSetValues("FD");
                        }}
                      >
                        Add FD
                      </button>
                    </div>
                  </div>
                </div>
                <div
                // className={`${styles.overflowYAuto} ${styles.maxH96} ${
                //   showScrollbar ? styles.showScrollbar : ""
                // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
                //   styles.scrollbarTrackPrimary
                // }`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  {FD && FD?.length > 0 ? (
                    <FDAssetTable data={FD} fetchDataFD={fetchDataFD} />
                  ) : (
                    <div className="w-full text-center text-darkGrey my-6 text-xl font-semibold">
                      No data is available for this asset type.
                    </div>
                  )}
                </div>
              </div>
            )}

            {PPFCheck && (
              <div className="mb-16">
                <div className="flex flex-col ">
                  <div className="flex gap-x-8 items-center justify-between">
                    <div className="flex flex-row w-full justify-start pb-4">
                      <div>
                        <h1 className="text-2xl font-semibold text-primary">
                          PPF/EPS/KWP
                        </h1>
                      </div>
                      <div className="pl-2">
                        <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                          {ppfLength}
                        </div>
                      </div>
                    </div>
                    <div className="w-[200px] flex justify-end items-start">
                      <button
                        className="text-white bg-primary hover:bg-secondary px-4 py-2 cursor-pointer rounded"
                        onClick={() => {
                          handleSetValues("PPF/EPS/KWP");
                        }}
                      >
                        Add PPF/EPS/KWP
                      </button>
                    </div>
                  </div>
                </div>
                <div
                // className={`${styles.overflowYAuto} ${styles.maxH96} ${
                //   showScrollbar ? styles.showScrollbar : ""
                // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
                //   styles.scrollbarTrackPrimary
                // }`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  {PPF && PPF?.length > 0 ? (
                    <PPFAssetTable data={PPF} fetchDataPPF={fetchDataPPF} />
                  ) : (
                    <div className="w-full text-center text-darkGrey my-6 text-xl font-semibold">
                      No data is available for this asset type.
                    </div>
                  )}
                </div>
              </div>
            )}
            {PrivateCheck && (
              <div className="mb-16">
                <div className="flex flex-col ">
                  <div className="flex gap-x-8 items-center justify-between">
                    <div className="flex flex-row w-full justify-start pb-4">
                      <div>
                        <h1 className="text-2xl font-semibold text-primary">
                          Unlisted Equity
                        </h1>
                      </div>
                      <div className="pl-2">
                        <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                          {privateStockLength}
                        </div>
                      </div>
                    </div>
                    <div className="w-[300px] flex justify-end items-start">
                      <button
                        className="text-white bg-primary hover:bg-secondary px-4 py-2 cursor-pointer rounded"
                        onClick={() => {
                          handleSetValues("PRIVATE_STOCK");
                        }}
                      >
                        Add Unlisted Equity
                      </button>
                    </div>
                  </div>
                </div>
                <div
                // className={`${styles.overflowYAuto} ${styles.maxH96} ${
                //   showScrollbar ? styles.showScrollbar : ""
                // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
                //   styles.scrollbarTrackPrimary
                // }`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  {privateStock && privateStock?.length > 0 ? (
                    <PrivateEquityAssetTable
                      data={privateStock}
                      fetchDataPrivateStock={fetchDataPrivateStock}
                    />
                  ) : (
                    <div className="w-full text-center text-darkGrey my-6 text-xl font-semibold">
                      No data is available for this asset type.
                    </div>
                  )}
                </div>
              </div>
            )}

            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <Box>
                <div className="flex items-center justify-between bg-black px-5 py-3">
                  <Typography variant="subtitle2" color="white">
                    Filter
                  </Typography>
                  <IconButton
                    onClick={handleClose}
                    sx={{ color: colors.white }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </div>

                <Box sx={{ padding: "1rem 2rem" }}>
                  <h6 className="text-md font-bold mb-2">Fund Type</h6>
                  {filterOptions.map((filter: any, index: number) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<Add />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <FormControlLabel
                          label={filter.name}
                          control={
                            <Checkbox
                              checked={filter.options.every(
                                (option: any) => option.isChecked
                              )}
                              onChange={(event) => handleChange1(event, index)}
                            />
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3,
                          }}
                        >
                          {filter.options.map((option: any, j: number) => (
                            <FormControlLabel
                              key={j}
                              label={option.name}
                              control={
                                <Checkbox
                                  checked={option.isChecked}
                                  onChange={(event) =>
                                    handleChange2(event, index, j)
                                  }
                                />
                              }
                            />
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                  {/* <h6 className="text-md font-bold mb-2 mt-10">Time Period</h6> */}

                  {/* <AccordionDetails>
                <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                  {timePeriodOptions.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      label={option.name}
                      control={
                        <Checkbox
                          checked={option.isChecked}
                          onChange={(event) =>
                            handleChange2(event, index, index)
                          }
                        />
                      }
                    />
                  ))}
                </Box>
              </AccordionDetails> */}

                  <Button
                    sx={{
                      backgroundColor: colors.bgGrey,
                      borderRadius: 2,
                      textTransform: "none",
                      padding: "0.5rem 2rem",
                      color: "#000",
                      marginTop: "1rem",
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Menu>
          </div>
        ) : (
          <div className="w-full text-center text-darkGrey my-10 text-2xl font-semibold">
            Please select asset type to see uploaded holdings data.
          </div>
        )}
      </div>
      {isModalOpen && (
        <AddHoldingsModalForm
          fields={fields}
          id={user?.id}
          holdingType={holdingType}
          heading={title}
          handleOpen={handleModalOpen}
          handleClose={handleClose2}
          onSubmit={handleSubmit}
        />
      )}
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default AssetHolding;
