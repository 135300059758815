/**
 * Formats a number or string value into Indian currency format.
 *
 * @param value - The numeric value to format, either as a number or string.
 * @returns A formatted string in the Indian numbering system with commas.
 */
export const formatIndianCurrency = (value: number | string): string => {
  let numericValue: number;

  // Parse input value to a number
  if (typeof value === "string") {
    numericValue = parseFloat(value);
  } else {
    numericValue = value;
  }

  // Handle invalid input
  if (isNaN(numericValue)) {
    return "Invalid number";
  }

  // Determine if the value is negative
  const isNegative = numericValue < 0;
  const absoluteValue = Math.abs(numericValue);

  // Convert the absolute value to string and split into integer and decimal parts
  const [integerPart, decimalPart] = absoluteValue.toString().split(".");

  // Format the integer part for numbers with more than 3 digits
  const formattedInteger = integerPart.length > 3
    ? integerPart.slice(0, -3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      integerPart.slice(-3)
    : integerPart;

  // Combine the integer part and decimal part (if any)
  const formattedNumber = decimalPart
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;

  // Add the negative sign if applicable
  return isNegative ? `-${formattedNumber}` : formattedNumber;
};
