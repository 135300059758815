import React, { useState } from 'react';
import { TableRow, IconButton, Collapse, Table, TableBody, TableCell, Container } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from '../../constants/colors';
import { toCamelCase } from '../../utils/ToCamelCase';
import { formatIndianCurrency } from '../../utils/formatIndianNumber';


const MFTableRowComponent = ({ data, getInitials, modalMenuClick }: any) => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);



    const handleExpandClick = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const isArray=Array.isArray(data);  

    return (
        <>
            {isArray && data?.map((item: any, index: number) => {
                const Name = item?.schemeName || 'N/A';

                return (
                    <React.Fragment key={item.id}>
                        <TableRow className="border-[2px] border-lightGrey hover:bg-lightBg">
                            <TableCell component="th" scope="row" className="min-w-[200px]" sx={{borderBottom:"2px solid #ccc"}}>
                                <div className="flex gap-x-2 items-center">
                                    <div className="w-12 h-10 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                                        {getInitials(Name)}
                                    </div>
                                    <div className="w-full ml-1">
                                        <span className="cursor-pointer" onClick={() => handleExpandClick(index)}>{toCamelCase(Name)}</span>
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{item?.schemeType.toUpperCase() || '-'}</TableCell>
                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{item?.transactionDate || '-'}</TableCell>
                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{Number(item?.units).toFixed(2) || '-'}</TableCell>
                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{"Rs."+formatIndianCurrency(Number(item?.costValue).toFixed(2)) || '-'}</TableCell>
                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{"Rs."+formatIndianCurrency(Number(item?.currentValue).toFixed(2)) || '-'}</TableCell>
                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{"Rs."+formatIndianCurrency(Number(item?.unrealisedPNL).toFixed(2))}</TableCell>
                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{item?.unrealisedPNLPercentage+"%" || '-'}</TableCell>
                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{Number(item?.currentNAV).toFixed(2) || '-'}</TableCell>
                            <TableCell align="center" className="min-w-[100px]" sx={{borderBottom:"2px solid #ccc"}}>{item?.folioNumber || '-'}</TableCell>
                        </TableRow>
                    {/* <TableRow sx={{ height: "5%" }}>
                  <TableCell
                    sx={{ border: "none" }}
                    colSpan={8}
                    align="center"
                  ></TableCell>
                </TableRow> */}
                    </React.Fragment>
                );
            })}
            
        </>
    );
};


export default MFTableRowComponent;
