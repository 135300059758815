import {
    Box,
    Checkbox,
    Collapse,
    Divider,
    Fade,
    IconButton,
    ListItem,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
  import React, { useCallback, useState } from "react";
  import { Dots } from "react-activity";
  import "react-activity/dist/library.css";
  import { useDispatch, useSelector } from "react-redux";
  import { getFDSchemeInfo, getFDinfo } from "../../redux/FD/FDSlice";
  import { Link, useLocation, useNavigate } from "react-router-dom";
  import { BorderBottom, InfoOutlined, MoreVert, ReportProblem } from "@mui/icons-material";
  import getInitials from "../../utils/getInitailasIMGUtils";
  import { colors } from "../../constants/colors";
  import { addToWatchlist, removeToWatchlist } from "../../redux/wishlists/wishlistSlice";
  import toast from "react-hot-toast";
  import InvestModal from "../modals/FDRequestModal";  
  import PMSTableRowComponent from "./PMSTableRowComponent";
  interface Data {
    schemes: Scheme[];
  }
  
  interface Scheme {
    schemeName: string;
    plans: Plan[];
    ratings: string;
    minInvestAmountinINR: {
      monthly: number;
      quaterly: number;
      halfYearly: number;
      annual: number;
      cumulativeDeposit: number;
      annualisedYield: number;
    };
    maxInvestAmountinINR: number;
  }
  
  interface Plan {
    id: number;
    interestRates: {
      annual: number;
    };
    name: string;
    plans: {};
    ratings: string;
    minInvestAmountInINR: number;
    maxInvestAmountinINR: number;
  }
  
  interface Result {
    highestRateOverall: number;
    planWithHighestRateOverall: Plan | null;
    highestRatePerScheme: { [schemeName: string]: { highestRate: number; highestPlanId: number } };
  }

  const ITEMS_PER_PAGE = 20;

  const Rows = ({ data, setLoading,setDataById, setTenureALL, openInvestModal,fetchDataPMS }: any) => {
    const dispatch = useDispatch<any>();
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const openMenuMD = Boolean(anchorMD);
    const [seniorCitizenRate, setSeniorCitizenRate] = useState<number | null>(null);
    const [womanSpecialRate, setWomanSpecialRate] = useState<number | null>(null);
    const [isinWishilist, setIsinWishilist] = useState<any>();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
  
    const modalMenuClick = (event: React.MouseEvent<HTMLElement>, isinWishilist: number, id: number) => {
      setAnchorMD(event.currentTarget);
      let data = { status: isinWishilist, id: id }
      setIsinWishilist(data)
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleCloseMD = () => {
      setAnchorMD(null);
    };
  
    const Watchlist = async () => {
      if (!isinWishilist) return;
  
      if (isinWishilist.status === 1) {
        const data = { itemId: isinWishilist.id, itemType: 6 };
        const response = await dispatch(removeToWatchlist(data));
        if (response.payload) {
          setIsinWishilist({ ...isinWishilist, status: 0 });
          toast.success('Removed from watchlist');
        } else {
          toast.error('Failed to remove from watchlist');
        }
      } else if (isinWishilist.status === 0) {
        const data = { itemId: isinWishilist.id, itemType: 6 };
        const response = await dispatch(addToWatchlist(data));
        if (response.payload) {
          setIsinWishilist({ ...isinWishilist, status: 1 });
          toast.success('Added to watchlist');
        } else {
          toast.error('Failed to add to watchlist');
        }
      }
    };
  
  
    const fetchData = async (id: number) => {
      setLoading(true);
      try {
        const response = await dispatch(getFDSchemeInfo({ schemeId: id }));
  
        if (response?.payload) {
          setDataById(response.payload.scheme);
          setTenureALL(response?.payload?.scheme?.tenures);
          openInvestModal();
        } else {
          toast.error("Try again later");
        }
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
      }
    }
    // console.log('data on Bond', data);
  
    return (
      <>
        <PMSTableRowComponent
          data={data}
          getInitials={getInitials}
          modalMenuClick={modalMenuClick}
          fetchDataPMS={fetchDataPMS}
        />
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorMD}
          open={openMenuMD}
          onClose={handleCloseMD}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#255288',
              color: '#fff',
              overflow: 'hidden',
              borderRadius: '10px',
              position: 'relative',
              width: isSmallScreen ? '80%' : '12%',
            },
          }}
          className="relative"
        >
          <div className="mx-6">
            <ListItem button onClick={handleClose} sx={{ marginLeft: '-25px' }}>
              <MenuItem className="text-xs sm:text-sm" onClick={Watchlist}>{isinWishilist?.status === 1 ? "Remove from watchlist" : 'Add to Watchlist'}</MenuItem>
            </ListItem>
            <Divider sx={{ backgroundColor: '#fff' }} />
            <ListItem button onClick={handleClose} sx={{ marginLeft: '-25px' }}>
              <MenuItem className="text-xs sm:text-sm" onClick={(e: any) => { fetchData(isinWishilist.id) }}>Invest</MenuItem>
            </ListItem>
          </div>
          <div
            className="absolute bg-white"
            style={{
              width: '20px',
              height: '20px',
              transform: 'rotate(315deg)',
              bottom: 'calc(100% - 10px)',
              left: 'calc(50% - 10px)',
            }}
          />
        </Menu>
      </>
    );
  };
  
  const PMSAssetTable = ({ data, loader ,fetchDataPMS}: any) => {
     const [page, setPage] = useState(0);
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: string | null }>({
      key: '',
      direction: null,
    });
  
    const getSortedData = useCallback(() => {
      if (!sortConfig.key) return data;
  
      const sortedData = [...data];
  
      switch (sortConfig.key) {
        case 'holdingName':
          sortedData.sort((a, b) => {
            const nameA = (a.holdingName || '').toUpperCase();
            const nameB = (b.holdingName || '').toUpperCase();
            return (nameA < nameB ? -1 : nameA > nameB ? 1 : 0) * (sortConfig.direction === 'ascending' ? 1 : -1);
          });
          break;
          case 'category':
          sortedData.sort((a, b) => {
            const nameA = (a.category || '').toUpperCase();
            const nameB = (b.category || '').toUpperCase();
            return (nameA < nameB ? -1 : nameA > nameB ? 1 : 0) * (sortConfig.direction === 'ascending' ? 1 : -1);
          });
          break;
            case 'realisedPnL':
            case 'investedAmount':
            case 'currentAmount':
            case 'xirr':
          sortedData.sort((a, b) => {
            const valueA = parseFloat(a[sortConfig.key]) || 0;
            const valueB = parseFloat(b[sortConfig.key]) || 0;
            return (valueA - valueB) * (sortConfig.direction === 'ascending' ? 1 : -1);
          });
          break;
          case 'absolutePnL':
            sortedData.sort((a, b) => {
              const valueA = typeof a[sortConfig.key] === 'string'
                ? parseFloat(a[sortConfig.key].replace('%', '')) // Remove '%' and parse as float
                : parseFloat(a[sortConfig.key]) || 0;
      
              const valueB = typeof b[sortConfig.key] === 'string'
                ? parseFloat(b[sortConfig.key].replace('%', '')) // Remove '%' and parse as float
                : parseFloat(b[sortConfig.key]) || 0;
      
              return (valueA - valueB) * (sortConfig.direction === 'ascending' ? 1 : -1);
            })
            break;
  
        default:
          break;
      }
  
      return sortedData;
    }, [data, sortConfig]);
  
    const handleSort = (key: string) => {
      setSortConfig(prev => ({
        key,
        direction: prev.key === key && prev.direction === 'ascending' ? 'descending' : 'ascending',
      }));
    };
  
    const sortedData = getSortedData();
    const paginatedData = sortedData.slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE);
    return (
      <>
        <TableContainer component={Paper} sx={{overflowY: 'hidden', overflowX: 'auto',boxShadow: 'none', borderTop: '2px solid #ccc',borderRight: '2px solid #ccc',borderLeft: '2px solid #ccc' }}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead sx={{
          backgroundColor: colors.lightBg,
          borderBottom: '2px solid #ccc',
          '& th': {
            backgroundColor: colors.lightBg, 
            fontWeight: 600,
            color: colors.primary,
            fontSize: '16px',
            borderBottom: '2px solid #ccc',
          },
        }}>
              <TableRow sx={{ border: 'none' }}>
                {/* <TableCell padding="checkbox" /> */}
                <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} >
                <div className={`flex items-center w-auto`}>
                    <>Scheme Name</>
                    <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                      <KeyboardArrowUpIcon
                        className="w-2 mb-[-10px] cursor-pointer"
                        onClick={() => handleSort('holdingName')}
                      />
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() => handleSort('holdingName')}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
             
                  <div className={`flex items-center w-auto justify-center`}>
                    <>Category</>
                    <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                      <KeyboardArrowUpIcon
                        className="w-2 mb-[-10px] cursor-pointer"
                        onClick={() => handleSort('category')}
                      />
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() => handleSort('category')}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
             
                  <div className={`flex items-center w-auto justify-center`}>
                    <>Invested Amount</>
                    <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                      <KeyboardArrowUpIcon
                        className="w-2 mb-[-10px] cursor-pointer"
                        onClick={() => handleSort('investedAmount')}
                      />
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() => handleSort('investedAmount')}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">                
                <div className={`flex items-center w-auto justify-center`}>
                    <>Current Amount</>
                    <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                      <KeyboardArrowUpIcon
                        className="w-2 mb-[-10px] cursor-pointer"
                        onClick={() => handleSort('currentAmount')}
                      />
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() => handleSort('currentAmount')}
                      />
                    </div>
                  </div>
                </TableCell>
                {/* <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">                  
                  <div className={`flex items-center w-auto justify-center`}>
                    <>Realised P&L</>
                    <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                      <KeyboardArrowUpIcon
                        className="w-2 mb-[-10px] cursor-pointer"
                        onClick={() => handleSort('realisedPnL')}
                      />
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() => handleSort('realisedPnL')}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">          
                  <div className={`flex items-center w-auto justify-center`}>
                    <>Unrealised P&L</>
                    <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                      <KeyboardArrowUpIcon
                        className="w-2 mb-[-10px] cursor-pointer"
                        onClick={() => handleSort('unrealisedPnL')}
                      />
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() => handleSort('unrealisedPnL')}
                      />
                    </div>
                  </div>
                </TableCell> */}
                <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">          
                  <div className={`flex items-center w-auto justify-center`}>
                    <>Absolute Return</>
                    <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                      <KeyboardArrowUpIcon
                        className="w-2 mb-[-10px] cursor-pointer"
                        onClick={() => handleSort('unrealisedPnL')}
                      />
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() => handleSort('unrealisedPnL')}
                      />
                    </div>
                  </div>
                </TableCell>
                {/* <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                  <div className={`flex items-center w-auto justify-center`}>
                    <>XIRR</>
                    <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                      <KeyboardArrowUpIcon
                        className="w-2 mb-[-10px] cursor-pointer"
                        onClick={() => handleSort('xirr')}
                      />
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() => handleSort('xirr')}
                      />
                    </div>
                  </div>
                </TableCell> */}
                <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                  <div className={`flex items-center w-auto justify-center`}>
                    Transactions
                  </div>
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                  <div className={`flex items-center w-auto justify-center`}>
                    Actions
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            {loader ? (
              <div className="flex justify-center items-center h-full">
                <div className="flex flex-col justify-center items-center">
                  <Dots />
                </div>
              </div>
            ) : (
              <TableBody className="" sx={{
                border: 'none',
                '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }} >
                 {/* <TableRow sx={{ height: '5%', }}>
                  <TableCell colSpan={8} align="center">
  
                  </TableCell>
                </TableRow> */}
  
                {sortedData && sortedData.length > 0 ? (
                  <Rows data={paginatedData} fetchDataPMS={fetchDataPMS}/>
                )
                  : <TableRow>
                    <TableCell colSpan={8} align="center">
                      No similar plans available.
                    </TableCell>
                  </TableRow>}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {sortedData.length > ITEMS_PER_PAGE && (
        <Box display="flex" justifyContent="center" mt={1}>
          <TablePagination
          component="div"
          count={sortedData.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={ITEMS_PER_PAGE}
          rowsPerPageOptions={[ITEMS_PER_PAGE]}
        />
        </Box>
      )}
      </>
    );
  };
  
  export default PMSAssetTable;