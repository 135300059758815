import api from "../../config/apiConfig";

const getAllMFHoldings = async () => {
    const accessToken = localStorage.getItem("accessToken");
    console.log('AssetsHolding Service at AssetsHolding APi access token ', accessToken);

    const response = await api.post("/funds/getHoldings", {}, {
        headers: {
            Authorization: accessToken,
        },
    });
    // console.log('FD Service Response : ',response);

    return response.data.data;
};



const getUnrealisedMFHoldings = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    console.log('AssetsHolding Service at AssetsHolding APi access token ', accessToken);

    const response = await api.post("funds/getUnrealisedReport", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const getAllFDHoldings = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    console.log('AssetsHolding Service at AssetsHolding APi access token ', accessToken);

    const response = await api.post("fd/getHoldings", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    // console.log('FD Service Response : ',response);

    return response.data.data;
};

const getHoldingsData = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    console.log('AssetsHolding Service at AssetsHolding APi access token ', accessToken);

    const response = await api.post("user/getUserHoldings", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    // console.log('FD Service Response : ',response);

    return response.data.data;
};

const uploadHoldingsData = async (formData: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/uploadHoldingsv3", formData, {
        headers: {
            Authorization: accessToken,
        },
    });

    return response;
};

const addSingleHoldingsData = async (formData: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/uploadHoldings", formData, {
        headers: {
            Authorization: accessToken,
        },
    });

    return response;
};

const editHoldingsData = async (formData: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/editHoldingsv2", formData, {
        headers: {
            Authorization: accessToken,
        },
    });

    return response;
};

const editHoldingsData2 = async (formData: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/editHoldings", formData, {
        headers: {
            Authorization: accessToken,
        },
    });

    return response;
};

const deleteHoldingsData = async (formData: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("user/deleteHoldings", formData, {
        headers: {
            Authorization: accessToken,
        },
    });

    return response;
};

const getSchemes = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("admin/getSchemeName", data, {
        headers: {
            Authorization: accessToken, // Ensure the token is formatted correctly
        },
    });
    return response.data;  
}

const AHService = { getAllFDHoldings, getAllMFHoldings ,getHoldingsData,getUnrealisedMFHoldings ,uploadHoldingsData ,deleteHoldingsData ,editHoldingsData,editHoldingsData2,addSingleHoldingsData,getSchemes};
export default AHService;