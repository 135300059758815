import api from "../../config/apiConfig";
import { NomineeDetails } from "../../models/nominee.model";

const addNominee = async (data: NomineeDetails) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/nominee/add", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const addNomineeV2 = async (data: NomineeDetails) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("nominee/addV2", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const updateNominees = async (data: NomineeDetails) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("nominee/update", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const allowNominees = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/user/allowNominee", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const uploadRTA = async (formData: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("/user/uploadRTA", formData, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const nomineeService = { addNominee, addNomineeV2, updateNominees,allowNominees,uploadRTA };
export default nomineeService;