import React from 'react';
import { Button } from '..'; // Adjust the import path as necessary
import { colors } from '../../constants/colors'; // Adjust the import path as necessary

interface ConfirmationModalProps {
    isVisible: boolean;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    buttonFirst?: string;
    buttonSecond?: string;
}

const ConfirmationModal2: React.FC<ConfirmationModalProps> = ({
    isVisible,
    message,
    onConfirm,
    onCancel,
    buttonFirst = 'Cancel',
    buttonSecond = 'Confirm',
}) => {
    if (!isVisible) {
        return null; // Return null when modal is not visible
    }

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50" style={{ zIndex: 9999 }}>
            <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
                <h2 className="text-2xl font-bold mb-4">Confirmation</h2>
                <p className="mb-6 text-lg">{message}</p>
                {/* Positioned at the bottom-right corner with proper spacing */}
                <div className="mt-auto flex justify-end gap-4">
                    <Button
                        onClick={onCancel}
                        className="bg-white text-primary hover:bg-orange hover:text-white border border-blue-950 hover:border-orange px-4 py-2 rounded-lg"
                    >
                        {buttonFirst}
                    </Button>
                    <Button
                        onClick={onConfirm}
                        className="bg-primary hover:bg-orange text-white px-4 py-2 rounded-lg"
                    >
                        {buttonSecond}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal2;
