import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { getSchemes } from "../../redux/asset_Holdings/assetSlice";

interface Field {
  name: string;
  label: string;
  type: "text" | "number";
}

interface ModalFormProps {
  fields: Field[];
  values?: Record<string, string | number>;
  id: string;
  holdingType: string;
  heading: string;
  handleOpen: () => void;
  handleClose: () => void;
  onSubmit: (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => void;
}

const AddHoldingsModalForm: React.FC<ModalFormProps> = ({
  fields,
  values,
  id,
  holdingType,
  heading,
  handleOpen,
  handleClose,
  onSubmit,
}) => {
  const dispatch = useDispatch<any>();
  const [formData, setFormData] = useState<Record<string, string | number>>({
    ...values,
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [schemes, setSchemes] = useState<
    {
      assetName: string;
      category: string;
      stockSymbol: string;
      bondCategory: string;
      currentNAV: number;
      isin: string;
      amcCode: string;
    }[]
  >([]);
  const [tradeType, setTradeType] = useState<string>("");

  const handleChange2 = (event: any) => {
    setTradeType(event.target.value);
    setFormData((prev) => ({
      ...prev,
      transType: event.target.value,
    }));
    console.log("Selected:", event.target.value); // Capture selected value
  };

  // useEffect(() => {
  //   handleSelectChange("");
  // }, []);

  const handleSelectChange = async (value: string) => {
    setSearchTerm(value); // Update the search input value

    try {
      const response = await dispatch(
        getSchemes({
          pageIndex: 1,
          pageSize: 100,
          search: value, // Use search term for real-time filtering
          holdingType:
            holdingType === "AIF"
              ? 5
              : holdingType === "PMS"
              ? 6
              : holdingType === "BOND"
              ? 1
              : holdingType === "PRIVATE_STOCK"
              ? 8
              : holdingType === "MF"
              ? 2
              : 4,
        })
      );
      // Filter out schemes where schemesName is "-" or ""
      const filteredSchemes = response?.payload?.schemeNames?.filter(
        (scheme: { assetName: string }) =>
          scheme.assetName !== "-" && scheme.assetName !== ""
      );

      setSchemes(filteredSchemes || []);
      setErrors({});
      // console.log(filteredSchemes, "filteredSchemes");
    } catch (error) {
      console.error("Error fetching schemes:", error);
      // toast.error("An error occurred while fetching schemes.");
    }
  };

  const handleSchemeSelect = (
    schemeName: string,
    category: string,
    stockSymbol: string,
    bondCategory: string,
    currentNAV: number,
    isin: string,
    amcCode: string
  ) => {
    // Set the selected scheme ID and update searchTerm with the selected name
    setSearchTerm(schemeName);
    setFormData((prev) => ({
      ...prev,
      assetName: schemeName,
      category: category,
      stockSymbol: stockSymbol,
      bondCategory: bondCategory,
      currentNAV: currentNAV,
      isin: isin,
      amcCode: amcCode,
    }));
    setSchemes([]);
  };

  const handleChange = (field: string, value: string | number) => {
    setFormData({ ...formData, [field]: value });
    if (value) {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const validateFields = () => {
    const newErrors: Record<string, string> = {};
    fields.forEach((field) => {
      if (!formData[field.name]) {
        console.log([field.name], "Validation Check");
        newErrors[field.name] = `${field.label} is required.`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    console.log(formData, "FormData");
    if (validateFields()) {
      onSubmit(formData, id, holdingType);
      handleClose();
      setFormData({});
      setSearchTerm("");
    }
  };

  return (
    <Modal open onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxHeight: "70vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          px: 4,
          py: 2,
          borderRadius: 2,
          overflowY: "auto",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" component="h2">
            {heading}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex" flexDirection="column" gap={1} mb={2}>
          {fields.map((field) => (
            <Box
              key={field.name}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ flex: 1, textAlign: "left", marginRight: 2 }}
              >
                {field.label}
              </Typography>
              {field.label === "MF Name" ||
              field.label === "FD Name" ||
              field.label === "PMS Name" ||
              field.label === "Bond Name" ||
              field.label === "Equity Name" ||
              field.label === "AIF Name" ? (
                <>
                  <TextField
                    label="Search"
                    variant="outlined"
                    name="assetName"
                    value={searchTerm || ""}
                    onChange={(e) => handleSelectChange(e.target.value)}
                    error={!!errors[field.name]}
                    onWheelCapture={(e: any) => e.target.blur()}
                    helperText={errors[field.name] || ""}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      flex: 2,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: !!errors[field.name]
                            ? "rgb(241, 122, 33) !important"
                            : "rgba(0, 0, 0, 0.23)!important",
                        },
                        "&:hover fieldset": {
                          borderColor: !!errors[field.name]
                            ? "rgb(241, 122, 33)"
                            : undefined,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: !!errors[field.name]
                            ? "rgb(241, 122, 33)"
                            : undefined,
                        },
                      },
                      "& .MuiFormHelperText-root": {
                        color: errors[field.name]
                          ? "rgb(241, 122, 33)!important"
                          : undefined,
                        fontSize: "0.9rem !important",
                      },
                    }}
                  />
                  {/* Display the filtered schemes */}
                  {schemes.length > 0 && (
                    <Box
                      sx={{
                        position: "absolute", // Makes it overlay instead of pushing elements down
                        zIndex: 10, // Ensures it appears above other elements
                        background: "#fff",
                        top: "29%",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        right: "5%",
                        borderRadius: "4px",
                        maxHeight: "200px",
                        overflowX: "auto",
                        overflowY: "auto",
                        width: "58%", // Ensure it matches the text field width
                        mt: 1,
                      }}
                    >
                      {schemes.map((scheme, index) => (
                        <MenuItem
                          key={index}
                          value={scheme.assetName}
                          onClick={() =>
                            handleSchemeSelect(
                              scheme.assetName,
                              scheme?.category,
                              scheme?.stockSymbol,
                              scheme?.bondCategory,
                              scheme?.currentNAV,
                              scheme?.isin,
                              scheme?.amcCode
                            )
                          }
                        >
                          {scheme.assetName}
                        </MenuItem>
                      ))}
                    </Box>
                  )}
                </>
              ) : field.label === "Transaction Type (Buy/Sell) " ? (
                <>
                  <Select
                    labelId="buy-sell-label"
                    value={tradeType}
                    onChange={handleChange2}
                    label=""
                    sx={{
                      flex: 2,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: !!errors[field.name]
                            ? "rgb(241, 122, 33) !important"
                            : "rgba(0, 0, 0, 0.23)!important",
                        },
                        "&:hover fieldset": {
                          borderColor: !!errors[field.name]
                            ? "rgb(241, 122, 33)"
                            : undefined,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: !!errors[field.name]
                            ? "rgb(241, 122, 33)"
                            : undefined,
                        },
                      },
                      "& .MuiFormHelperText-root": {
                        color: errors[field.name]
                          ? "rgb(241, 122, 33)!important"
                          : undefined,
                        fontSize: "0.9rem !important",
                      },
                    }}
                  >
                    <MenuItem value="Buy">Buy</MenuItem>
                    <MenuItem value="Sell">Sell</MenuItem>
                  </Select>
                </>
              ) : (
                <TextField
                  type={field.type}
                  value={formData[field.name] || ""}
                  onChange={(e) => handleChange(field.name, e.target.value)}
                  error={!!errors[field.name]}
                  onWheelCapture={(e: any) => e.target.blur()}
                  helperText={errors[field.name] || ""}
                  variant="outlined"
                  sx={{
                    flex: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: !!errors[field.name]
                          ? "rgb(241, 122, 33) !important"
                          : "rgba(0, 0, 0, 0.23)!important",
                      },
                      "&:hover fieldset": {
                        borderColor: !!errors[field.name]
                          ? "rgb(241, 122, 33)"
                          : undefined,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: !!errors[field.name]
                          ? "rgb(241, 122, 33)"
                          : undefined,
                      },
                    },
                    "& .MuiFormHelperText-root": {
                      color: errors[field.name]
                        ? "rgb(241, 122, 33)!important"
                        : undefined,
                      fontSize: "0.9rem !important",
                    },
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="center">
          <button
            className="px-4 py-2 text-darkGrey bg-white hover:bg-secondary hover:text-white border border-darkGrey hover:border-secondary rounded mr-4"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-primary hover:bg-secondary text-white border rounded"
            onClick={handleSubmit}
          >
            Add Transaction
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddHoldingsModalForm;
