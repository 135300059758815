import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import Details from "./Details";
import Transactions from "../../components/Transactions/Transactions";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import {
  fetchDashboardData,
  getAnalyticsData,
  getFinanceNewData,
} from "../../redux/Dashboard/DashboardSlice";
import toast, { LoaderIcon } from "react-hot-toast";
import PieChart_Component from "../../components/PieChart/PieChart";
import getInitials from "../../utils/getInitailasIMGUtils";
import { importFunds, mfSendOTP, mfVerifyOTP } from "../../redux/MF/MFSlice";
import VerifyOTPModal from "../../components/modals/VerifyOTPModal";
import { getUserById } from "../../redux/user/userSlice";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import FinancialNewzCard from "../../components/FinancialNewz/FinancialNewzCard";
import BarChartData from "../../components/AllCharts/BarChartData";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


interface MutualFundTransaction {
  id: number;
  schemeName: string;
  orderType: "MF";
  amount: number;
  units: number;
  purchaseNAV: number;
  currentValue: number;
  isBuy: number;
  orderStatus: string;
  paymentStatus: string | null;
  createdAt: string;
  transactionDate: string;
  investedAmount: number;
}

interface FixedDepositTransaction {
  id: number;
  name: string;
  amount: number;
  interesetRate: number;
  returnAmount: number;
  fdStatus: number;
  createdAt: string;
  orderType: "FD";
  transactionDate: string;
  investedAmount: number;
}

interface DashboardData {
  AMC: any[];
  totalValueInvestedMF: number;
  currentValueMF: number;
  unrealisedGainLossMF: number;
  rioMF: number;
  totalValueInvestedFD: number;
  totalValueInvestedMFAumCapital: number;
  currentValueMFAumCapital: number;
  roiMFAumCapital: number;
  unrealisedGainLossMFAumCapital: number;
  totalFinalValueFD: number;
  unrealisedGainLossFD: number;
  roiFD: number;
  totalValueAtCost: number;
  recentTransactions: [MutualFundTransaction[], FixedDepositTransaction[]];
  roiAif: number;
  roiBond: number;
  roiPms: number;
  roiStock: number;
  totalFinalValueAif: number;
  totalFinalValueBond: number;
  totalFinalValuePms: number;
  totalFinalValueStock: number;
  totalValueInvestedAif: number;
  totalValueInvestedBond: number;
  totalValueInvestedPms: number;
  totalValueInvestedStock: number;
  unrealisedGainLossAif: number;
  unrealisedGainLossBond: number;
  unrealisedGainLossPms: number;
  unrealisedGainLossStock: number;
  totalPortfolioValue: number;
  totalUnrealisedGainLoss: number;
  totalUnrealisedGainLossPercentage: number;
  totalValueInvestedPrivateStock: number;
  totalFinalValuePrivateStock: number;
  unrealisedGainLossPrivateStock: number;
  roiPrivateStock: number;
  totalValueInvestedPPF: number;
  totalFinalValuePPF: number;
  unrealisedGainLossPPF: number;
  roiPPF: number;
  lastImportDate: any;
}
interface Data {
  title: string;
  totalAmount: string;
  profitAmount: string;
  unrealised: string;
  roi: string;
  handleSwitch?: () => void;
  othersCard?: boolean;
  setOthersCard?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMember?: any;
}

const MainDashboard = () => {
  const user = useUserData();
  const { todayImportDone } = useUserData();
  const [selectedMember, setSelectedMember] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [alignment, setAlignment] = useState<string | null>("1Y");
  const [dashboard, setMDData] = useState<DashboardData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [financialLoading, setFinancialLoading] = useState<boolean>(false);
  const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(false);
  const [othersCard, setOthersCard] = useState<boolean>(false);
  const [tabState, setTabState] = useState<"AumCapital" | "Consolidated">(
    "Consolidated"
  );
  // const [tabStateAnalytics, setTabStateAnalytics] = useState<
  //   "Weekly" | "Yearly"
  // >("Weekly");
  const [finalData, setFinalData] = useState<Data[]>([]);
  const [newzData, setNewzData] = useState<any[]>([]);
  const [analyticsData, setAnalyticsData] = useState<any>({});
  const dispatch = useDispatch<any>();
  const [UserID, setUserID] = useState(null);

  const [DataTransfer, setDataTransfer] = useState({
    riskmeter: "Moderately High",
    portfolioSummary: "Portfolio Summary",
    totalValueCost: "1,24,00,750.00",
    UnrealisedGL: "2,23,00,250.00",
    PortfolioValue: "3,48,00,500.00",
    ROI: "23",
  });
  const [OTPData, setOTPData] = useState({
    otpRef: "",
    clientRefNo: "",
    reqId: 0,
  });
  const [showSummary, setShowSummary] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (id: any, name: string) => {
    setSelectedMember(name);
    setAnchorEl(null);
    fetchData(id);
  };

  const handleOtpChange = (event: any) => {
    setOtp(event.target.value);
  };

  const calculateDates = () => {
    const endDate = new Date(); // Current date
    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1); // Set to one year ago

    return {
      startDate: startDate.getTime(), // Convert to timestamp
      endDate: endDate.getTime(), // Convert to timestamp
    };
  };

  const fetchData = async (id?: any) => {
    const { startDate, endDate } = calculateDates();
    const data = {
      fetchFor: tabState.toLowerCase(),
      startDate,
      endDate,
      userId: id ? id : UserID || "",
    };

    try {
      const response = await dispatch(fetchDashboardData(data));
      if (response.payload.data) {
        setMDData(response.payload.data);
      }
    } catch (error) {
      toast.error("Please Refresh");
      // setLoading(false);
    }
  };

  const fetchNewz = async () => {
    setFinancialLoading(true);
    try {
      const response = await dispatch(getFinanceNewData());
      if (response?.payload?.data?.data) {
        setNewzData(response?.payload?.data?.data);
        setFinancialLoading(false);
      }
    } catch (error) {
      toast.error("Please Refresh");
      setFinancialLoading(false);
    }
  };

  const AnalyticsData = async () => {
    setAnalyticsLoading(true);
    try {
      const response = await dispatch(getAnalyticsData({filter:"Daily"}));
      if (response?.payload?.data?.data) {
        setAnalyticsData(response?.payload?.data?.data);
        setAnalyticsLoading(false);
      }
    } catch (error) {
      toast.error("Please Refresh");
      setAnalyticsLoading(false);
    }
  };

  useEffect(() => {
    fetchNewz();
    AnalyticsData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [tabState]);

  const convertToIST = (timestamp: any): string => {
    const date = new Date(timestamp); // Convert the timestamp to a Date object
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Kolkata", // Set the timezone to IST
    };

    return date.toLocaleString("en-IN", options);
  };

  const handleSubmit = async () => {
    if (OTPData && otp) {
      const updatedOTPData = { ...OTPData, otp: otp };
      setOTPData(updatedOTPData);

      if (updatedOTPData) {
        try {
          const verifyResponse = await dispatch(mfVerifyOTP(updatedOTPData));
          if (verifyResponse.payload.data.success === 400) {
            toast.error("Invalid OTP");
          }

          if (verifyResponse.payload.data.success === 200) {
            setShowSummary(false);
            setLoadingModal(true);
            let fetechImport;
            const importData = {
              clientRefNo: updatedOTPData.clientRefNo,
              reqId: updatedOTPData.reqId,
              isRefresh: 0,
            };

            const fetchImportData = async () => {
              fetechImport = await dispatch(importFunds(importData));
              if (
                fetechImport?.payload.success === 400 &&
                fetechImport.payload.message ===
                  "We are in process of generating the CAS. Please visit after sometime."
              ) {
                toast.success(
                  "We are in process of generating the CAS Please Refresh the after 20s."
                );
                dispatch(getUserById({}));
                fetchData();
              } else if (fetechImport?.payload.data.success === 200) {
                toast.success("Mutual fund Data Sync Request submitted");
                dispatch(getUserById({}));
                fetchData();
                setLoadingModal(false);
              } else {
                toast.error("Request Failed");
              }
            };

            setTimeout(fetchImportData, 20000);
            setOTPData({
              otpRef: "",
              clientRefNo: "",
              reqId: 0,
            });
            setOtp("");
          } else {
            toast.error("Request Failed");
          }
        } catch (error) {
          toast.error("An error occurred while processing your request.");
        } finally {
          // setLoadingModal(false);
          // dispatch(hideLoading())
        }
      }
    } else {
      toast.error("Please enter the OTP.");
    }
  };

  const otpGeneration = () => {
    if (user?.userType === 5) {
      return;
    } else {
      const sendOtp = async () => {
        // if (todayImportDone === 1) {
        //   fetchData();
        // } else if (todayImportDone === 0) {
        try {
          setShowSummary(true);
          const response = await dispatch(mfSendOTP());

          if (response.payload.reqId) {
            toast.success("OTP Sent Successfully");
            setOTPData(response.payload);
          } else {
            toast.error(
              "No mutual fund found on provided pan card and mobile number."
            );
            setShowSummary(false);
          }
        } catch (error) {
          toast.error("OTP Send Failed");
          setShowSummary(false);
        }
        // }
      };
      sendOtp();
    }
  };

  // useEffect(() => {
  //   if (user?.userType === 5) {
  //     return;
  //   } else {
  //     const sendOtp = async () => {
  //       if (todayImportDone === 1) {
  //         fetchData();
  //       } else if (todayImportDone === 0) {
  //         try {
  //           setShowSummary(true);
  //           const response = await dispatch(mfSendOTP());

  //           if (response.payload.reqId) {
  //             toast.success("OTP Sent Successfully");
  //             setOTPData(response.payload);
  //           } else {
  //             toast.error(
  //               "No mutual fund found on provided pan card and mobile number."
  //             );
  //             setShowSummary(false);
  //           }
  //         } catch (error) {
  //           toast.error("OTP Send Failed");
  //           setShowSummary(false);
  //         }
  //       }
  //     };
  //     sendOtp();
  //   }
  // }, [dispatch, todayImportDone]);

  const currentDate = new Date();

  const formattedDate = currentDate
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })
    .toUpperCase();

  const formattedDateWithMonthInCaps = formattedDate.replace(
    /\b\w+\b/,
    (month) => month.toUpperCase()
  );

  useEffect(() => {
    const verticalBar: Data[] = [
      {
        title: "AIF",
        totalAmount: (dashboard?.totalValueInvestedAif ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValueAif ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossAif ?? 0).toString(),
        roi: (dashboard?.roiAif ?? 0).toString(),
      },
      {
        title: "PMS",
        totalAmount: (dashboard?.totalValueInvestedPms ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValuePms ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossPms ?? 0).toString(),
        roi: (dashboard?.roiPms ?? 0).toString(),
      },
      {
        title: "BOND",
        totalAmount: (dashboard?.totalValueInvestedBond ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValueBond ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossBond ?? 0).toString(),
        roi: (dashboard?.roiBond ?? 0).toString(),
      },
      {
        title: "FD",
        totalAmount: (dashboard?.totalValueInvestedFD ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValueFD ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossFD ?? 0).toString(),
        roi: (dashboard?.roiFD ?? 0).toString(),
      },
      {
        title: "EQUITY",
        totalAmount: (dashboard?.totalValueInvestedStock ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValueStock ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossStock ?? 0).toString(),
        roi: (dashboard?.roiStock ?? 0).toString(),
      },
      {
        title: "Private Stock",
        totalAmount: (
          dashboard?.totalValueInvestedPrivateStock ?? 0
        ).toString(),
        profitAmount: (dashboard?.totalFinalValuePrivateStock ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossPrivateStock ?? 0).toString(),
        roi: (dashboard?.roiPrivateStock ?? 0).toString(),
      },
      {
        title: "PPF/EPS/KWP",
        totalAmount: (dashboard?.totalValueInvestedPPF ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValuePPF ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossPPF ?? 0).toString(),
        roi: (dashboard?.roiPPF ?? 0).toString(),
      },
    ];

    let data: Data[] = [];
    if (tabState === "AumCapital") {
      data = [
        {
          title: "Mutual fund",
          totalAmount: (
            dashboard?.totalValueInvestedMFAumCapital ?? 0
          ).toString(),
          profitAmount: (dashboard?.currentValueMFAumCapital ?? 0).toString(),
          unrealised: (
            dashboard?.unrealisedGainLossMFAumCapital ?? 0
          ).toString(),
          roi: (dashboard?.roiMFAumCapital ?? 0).toString(),
        },
      ];
    } else {
      data = [
        {
          title: "Mutual fund",
          totalAmount: (dashboard?.totalValueInvestedMF ?? 0).toString(),
          profitAmount: (dashboard?.currentValueMF ?? 0).toString(),
          unrealised: (dashboard?.unrealisedGainLossMF ?? 0).toString(),
          roi: (dashboard?.rioMF ?? 0).toString(),
        },
      ];
    }

    setFinalData([...data, ...verticalBar]);
  }, [tabState, dashboard]);

  const handleSwitchCard = () => {
    setOthersCard(!othersCard);
  };

  const handleAllFamilyData = async () => {
    setSelectedMember("All");
    setAnchorEl(null);
    try {
      const response = await dispatch(getAnalyticsData({ filter: "Yearly" }));
      const res = response?.payload?.data?.data;
      if (res) {
        setMDData(res?.familyMembersData);
      }
    } catch (error) {
      toast.error("Please Refresh");
      // setLoading(false);
    }
  };

  let PieChart_Value = dashboard?.totalValueAtCost
    ? dashboard?.totalValueAtCost
    : 0;

  return (
    <>
      {loading ? (
        <LoaderIcon />
      ) : (
        <div className="pb-20">
          <div className=" bg-lightBg">
            <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto">
              <div className="flex flex-col sm:flex-row gap-x-16 items-center">
                <div className="bg-white p-4 rounded-xl items-center border border-gray-500 min-w-[180px] max-w-[250px]">
                  {/* border-b-2 border-bgGrey  pb-4*/}
                  <div className="flex flex-row gap-4   justify-center items-center">
                    <div className="bg-primary rounded-full w-1/2 h-12 flex items-center justify-center text-white mr-2 text-xl">
                      {getInitials(user?.firstName)}
                    </div>
                    <div className="flex flex-col">
                      <span className="font-bold text-sm text-nowrap">Hi,</span>
                      <span className="font-bold text-sm text-nowrap">
                        {user?.firstName}
                      </span>
                      {/* <span className="text-sm">Head of the family</span> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-3 flex-1 w-full ">
                  <div className="flex justify-between border-b-2 border-lightblueBorder">
                    <div className=" pb-3 sm:text-start text-center">
                      <h5 className="text-3xl font-medium text-primary">
                        {`${
                          DataTransfer.portfolioSummary || `Portfolio Summary`
                        }${selectedMember === "" ? "" : ", " + selectedMember}`}
                      </h5>
                      <span className="font-semibold">
                        As on {formattedDateWithMonthInCaps}
                      </span>
                    </div>
                  </div>

                  <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-4 w-full my-3 items-center text-nowrap">
                    <div className="flex flex-col">
                      <span className="text-gray-700_01 text-xl">
                        Total Value at Cost
                      </span>
                      <span className="text-gray-700_01 font-bold">
                        {formatIndianCurrency(
                          Number(
                            dashboard?.totalValueAtCost
                              ? dashboard?.totalValueAtCost
                              : 0
                          ).toFixed(2) || 0
                        )}
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <span className="text-gray-700_01 text-xl">
                        Portfolio Value
                      </span>
                      <span className="text-gray-700_01 font-bold">
                        {formatIndianCurrency(
                          Number(
                            dashboard?.totalPortfolioValue
                              ? dashboard?.totalPortfolioValue
                              : 0
                          ).toFixed(2) || 0
                        )}
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <span className="text-gray-700_01 text-xl">
                        Unrealised Gain/Loss
                      </span>
                      <span className="text-gray-700_01 font-bold">
                        {formatIndianCurrency(
                          Number(
                            dashboard?.totalUnrealisedGainLoss
                              ? dashboard?.totalUnrealisedGainLoss
                              : 0
                          ).toFixed(2)
                        )}
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <span className="text-gray-700_01 text-xl">
                        Absolute Return
                      </span>
                      <span className="text-gray-700_01 font-bold">
                        {formatIndianCurrency(
                          Number(
                            dashboard?.totalUnrealisedGainLossPercentage
                              ? dashboard?.totalUnrealisedGainLossPercentage
                              : 0
                          ).toFixed(2)
                        )}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`px-8 md:px-[60px] w-full mx-auto pt-8 max-w-[1440px]`}
          >
            {analyticsLoading ? (
              <div className="flex justify-center items-center h-[20vh] w-full">
                <LoaderIcon style={{ width: "60px", height: "60px" }} />
              </div>
            ) : (
              <>
                {/* <div className="flex justify-end">
                  <div className="w-3/5 text-[10px] sm:text-[16px] lg:w-1/5 flex items-center justify-center border-[1px] border-lightGrey gap-x-1 rounded-lg">
                    <span
                      onClick={() => {
                        setTabStateAnalytics("Weekly");
                      }}
                      className={`flex p-2 rounded-lg w-full items-center justify-center whitespace-nowrap ${
                        tabStateAnalytics === "Weekly"
                          ? "bg-primary text-white"
                          : "text-primary"
                      }`}
                    >
                      Weekly
                    </span>
                    <span
                      onClick={() => {
                        setTabStateAnalytics("Yearly");
                      }}
                      className={`flex p-2 rounded-lg w-full items-center justify-center whitespace-nowrap ${
                        tabStateAnalytics === "Yearly"
                          ? "bg-primary text-white"
                          : "text-primary"
                      }`}
                    >
                      Yearly
                    </span>
                  </div>
                </div> */}
                <BarChartData data={analyticsData} type="Daily" />
              </>
            )}
          </div>
          {/* tab button */}
          <div
            className={`px-8 md:px-[60px] w-full mx-auto pt-8  ${
              !dashboard?.totalValueAtCost ? "max-w-[1200px]" : "max-w-[1440px]"
            }`}
          >
            <div className="flex justify-end">
              <div className="w-3/5 text-[10px] sm:text-[16px] lg:w-1/5 flex items-center justify-center border-[1px] border-lightGrey gap-x-1 rounded-lg">
                <span
                  onClick={() => {
                    setTabState("AumCapital");
                  }}
                  className={`flex p-2 rounded-lg w-full items-center justify-center whitespace-nowrap ${
                    tabState === "AumCapital"
                      ? "bg-primary text-white"
                      : "text-primary"
                  }`}
                >
                  Aum Capital
                </span>
                <span
                  onClick={() => {
                    setTabState("Consolidated");
                  }}
                  className={`flex p-2 rounded-lg w-full items-center justify-center whitespace-nowrap ${
                    tabState === "Consolidated"
                      ? "bg-primary text-white"
                      : "text-primary"
                  }`}
                >
                  Consolidated
                </span>
              </div>

              {user?.familyMembers?.length > 0 && (
                <div className="ml-2">
                  <Button
                    variant="outlined"
                    endIcon={<ArrowDropDownIcon />}
                    sx={{
                      fontSize: "14.5px",
                      fontWeight: 500,
                      textTransform: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                      padding: "7px 10px",
                      borderRadius: "8px",
                      width: "160px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid blue",
                      borderColor: "lightGrey",
                      backgroundColor: "rgb(37, 82, 136)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "rgb(37, 82, 136)",
                      },
                    }}
                    onClick={handleDropdownClick}
                  >
                    <span className="text-center">
                      {selectedMember || "Family Members"}
                    </span>
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    disableScrollLock={true}
                    onClose={handleMenuClose}
                    sx={{
                      "& .MuiPaper-root": {
                        minWidth: anchorEl?.offsetWidth || 0, // Match the width of the button
                      },
                    }}
                  >
                    <MenuItem
                      onClick={handleAllFamilyData}
                      sx={{
                        width: "100%", // Ensure menu items take full width of the menu
                      }}
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleMenuItemClick(user?.id, user?.firstName);
                        setUserID(user?.id);
                      }}
                      sx={{
                        width: "100%", // Ensure menu items take full width of the menu
                      }}
                    >
                      {user?.firstName}
                    </MenuItem>
                    {user?.familyMembers?.map((member: any) => (
                      <MenuItem
                        key={member.id}
                        onClick={() => {
                          handleMenuItemClick(member?.id, member.firstName);
                          setUserID(member?.id);
                        }}
                        sx={{
                          width: "100%", // Ensure menu items take full width of the menu
                        }}
                      >
                        {member.firstName}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              )}
            </div>
            {/* Investment Summary & Investment Breakup */}
            <div className="flex flex-col md:flex-col lg:flex-row h-full">
              {PieChart_Value > 0 && (
                <div className="md:w-full lg:w-1/2 flex flex-col border border-gray-300 rounded-lg my-4 p-4 overflow-hidden lg:max-h-[735px] max-h-full">
                  <h1 className="text-primary font-bold text-2xl text-left mb-6">
                    Investment Summary
                  </h1>
                  <PieChart_Component
                    data={dashboard}
                    additionalData={finalData}
                  />
                </div>
              )}
              <div
                className={`flex flex-col border border-gray-300 rounded-lg my-4  ml-0 lg:ml-4 w-full p-4 overflow-auto`}
              >
                <div className="flex items-center w-full justify-between">
                  <h1 className="text-primary font-bold text-2xl">
                    Investment Breakup
                    {dashboard?.lastImportDate && (
                      <p className="text-[16px] text-darkGrey font-semibold">
                        * Mutual Fund data last fetched on :{" "}
                        {convertToIST(dashboard?.lastImportDate)} IST
                      </p>
                    )}
                  </h1>
                 {user?.userType === 1 && (
                   <div className="flex items-center">
                   <button
                     className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-secondary border"
                     onClick={otpGeneration}
                   >
                     Refresh MF
                   </button>
                   <Tooltip
                     title={
                       <span style={{ fontSize: "16px", color: "gray" }}>
                         After clicking this button, you will receive your
                         updated Mutual Fund data.
                       </span>
                     }
                     arrow
                     componentsProps={{ tooltip: { sx: { background: "white",boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", 
                       border: "1px solid lightgray" } }, arrow: { sx: { color: "lightgray" } }, }}
                   >
                     <IconButton aria-label="info" className="ml-4">
                       <InfoOutlinedIcon color="primary" />
                     </IconButton>
                   </Tooltip>
                 </div>
                 )}
                </div>
                <div>
                  <Details
                    data={finalData}
                    selectedMember={selectedMember}
                    additionalData={dashboard}
                    handleSwitchCard={handleSwitchCard}
                    othersCard={othersCard}
                    setOthersCard={setOthersCard}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="w-full md:w-2/5 mb-8">
                <h1 className="text-primary font-bold text-2xl">
                  Financial News
                </h1>
              </div>
              {financialLoading ? (
                <div className="flex justify-center items-center h-[20vh] w-full">
                  <LoaderIcon style={{ width: "60px", height: "60px" }} />
                </div>
              ) : (
                <FinancialNewzCard data={newzData} />
              )}
            </div>
            <div className="mt-16">
              {/* {dashboard?.recentTransactions &&
                (dashboard?.recentTransactions[0]?.length > 0 ||
                  dashboard?.recentTransactions[1]?.length > 0) && (
                  <div className="w-2/5 mb-8">
                    <h1 className="text-primary font-bold text-2xl">
                      Transactions
                    </h1>
                  </div>
                )} */}
              {dashboard?.recentTransactions &&
                dashboard?.recentTransactions?.length > 0 && (
                  <div className="w-full md:w-2/5 mb-8">
                    <h1 className="text-primary font-bold text-2xl">
                      Recent Transactions
                    </h1>
                  </div>
                )}
              <Transactions data={dashboard?.recentTransactions || [[], []]} />
            </div>
          </div>
        </div>
      )}
      <VerifyOTPModal
        title="Verify OTP sent to your mobile"
        showModal={showSummary}
        setShowModal={setShowSummary}
        otp={otp}
        setOtp={setOtp}
        onClick={handleSubmit}
      />

      <Modal
        open={loadingModal}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Adds a slight overlay effect
          }}
        >
          <Box
            sx={{
              width: "500px",
              padding: "20px",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              id="loading-modal-title"
              variant="h6"
              component="h5"
              sx={{
                marginBottom: 2,
                color: "#333",
                fontWeight: "bold",
              }}
            >
              {` MF Report generation can take upto 5 minutes, please wait`}
            </Typography>
            <CircularProgress />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MainDashboard;
