import React from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { colors } from "../../constants/colors";
import img from "../../assets/images/Tax_Rate.png";

const TaxReckoner: React.FC = () => {
  return (
    <Container maxWidth="lg" className="py-10">
      <Paper elevation={3} className="p-6">
        <div className="flex flex-col items-center justify-center">
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
            FOR RESIDENTS AND NON-RESIDENTS
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ textAlign: "start" }}>
            (For investments redeemed on or after 23rd July 2024)
          </Typography>
        </div>
        <TableContainer
          component={Paper}
          sx={{ marginY: "20px", backgroundColor: "#dee6de" }}
        >
          <Table>
            {/* Table Head */}
            <TableHead sx={{ border: "2px solid white" }}>
              <TableRow sx={{ backgroundColor: colors.primary }}>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    border: "2px solid white",
                  }}
                >
                  Mutual Fund Scheme Category
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                    border: "2px solid white",
                  }}
                >
                  Holding period (To qualify for LTCG)
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                    border: "2px solid white",
                  }}
                >
                  STCG Tax
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                    border: "2px solid white",
                  }}
                >
                  LTCG Tax
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody sx={{ border: "2px solid white" }}>
              <TableRow sx={{ backgroundColor: "#4d8bf9" }}>
                <TableCell
                  colSpan={4}
                  sx={{
                    border: "2px solid white",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Equity oriented MF ({">"}= 65% Domestic Equity Shares)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Investments redeemed on or after 23 July 2024
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  {">"}12 months
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  20%
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  12.50%
                </TableCell>
              </TableRow>

              <TableRow sx={{ backgroundColor: "#4d8bf9" }}>
                <TableCell
                  colSpan={4}
                  sx={{
                    border: "2px solid white",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  <span>
                    Specified MF / Debt oriented MF (FoF & ETF) ({">"}= 65% SEBI
                    Regulated Debt and Money Market){" "}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    border: "2px solid white",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: colors.primary,
                  }}
                >
                  <span>Investments before 1st April 2023</span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed between 1st April 2024 and 22nd July 2024
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  {">"}36 months
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  20%*
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed on or after 23rd July 2024
                  <p className="text-xs">
                    (Holding period for ETF for LTCG {">"}12 month)
                  </p>
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  {">"}24 months
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  12.50%*
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Investments after 1st April 2023 – Redeemed any time
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Not Applicable
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#4d8bf9" }}>
                <TableCell
                  colSpan={4}
                  sx={{
                    border: "2px solid white",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  <span>Multi-Asset Allocation Fund ({">"}= 65% Debt) </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed between 1st April 2024 and 22nd July 2024
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  {">"}36 months
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  20%*
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed on or after 23 July 2024
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  {">"}24 months
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  12.50%*
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#4d8bf9" }}>
                <TableCell
                  colSpan={4}
                  sx={{
                    border: "2px solid white",
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "white",
                  }}
                >
                  <span>
                    Other MFs (Gold & Silver ETFs, FOFs, International –
                    Funds/FOFs)
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    border: "2px solid white",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: colors.primary,
                  }}
                >
                  <span>Investments before 1st April 2023</span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed between 1st April 2024 and 22nd July 2024
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  {">"}36 months
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  20%*
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed on or after 23rd July 2024
                  <p className="text-xs">
                    (Holding period for ETF for LTCG {">"}12 month)
                  </p>
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  {">"}24 months
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  12.50%*
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    border: "2px solid white",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: colors.primary,
                  }}
                >
                  <span>Investments after 1st April 2023</span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed between 1st April 2024 and 22nd July 2024
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Not Applicable
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed between 23rd July 2024 to 31st March 2025
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Not Applicable
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "2px solid white" }}>
                  Redeemed on or after 1st April 2025
                  <p className="text-xs">
                    (Holding period for ETF for LTCG {">"}12 month)
                  </p>
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  {">"}24 months
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  Slab rate
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center", border: "2px solid white" }}
                >
                  12.50%*
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="space-y-6">
          {/* Section 1 */}
          <Box>
            <Typography className="text-justify">
              Notes: The above rates are exclusive of surcharge and cess. No
              change in surcharge and cess rates. *With Indexation. Tax rates
              for st non-residents is at par with residents. The definition of
              specified mutual fund has been amended starting 1 April 2025 i.e
              changed from investing total proceeds in not more than 35% in
              equity shares of domestic companies to investing more than 65% of
              its total proceeds in debt & money market instruments. We have
              considered the amendment in the above table. Capital gain
              exemption of \1,00,000 on transfer of equity oriented MFs u/s 112A
              has been increased to $1,25,000 w.e.f 23rd July 2024.
            </Typography>
          </Box>
          <Box className="mt-4">
            <Typography className="text-justify">
              Disclaimer: We are not tax consultants and nor do we provide any
              tax or legal advice The information provided to you has been
              prepared on the basis of our past experience and information
              available in the public domain. Request you to kindly consult with
              your own tax or professional advisors for any tax or legal matter.
              The company or its employees accept no responsibility for any loss
              suffered by any investor as a result of the said information
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default TaxReckoner;
