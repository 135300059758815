import React, { useState, useEffect } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"; // Make sure to import this
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { DateUtils } from "../../utils/FormatDate";

interface Transaction {
  id: number;
  schemeName?: string;
  name?: string;
  holdingName?: string;
  orderType?: string;
  amount?: number;
  investedAmount?: number;
  currentAmount?: number;
  createdAt?: string;
  transactionDate?: string;
}

interface TransactionsProps {
  data?: (Transaction | Transaction[])[]; 
}

const Transactions: React.FC<TransactionsProps> = ({ data }) => {
  const [recentTransactions, setRecentTransactions] = useState<Transaction[][]>([]);
  const [selectedTab, setSelectedTab] = useState<"recent" | "upcoming">("recent");
  const [showAllStates, setShowAllStates] = useState<boolean[]>([]); // State to track showAll for each transaction set

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      if (Array.isArray(data[0])) {
        const transactions = data as Transaction[][]; // Array of arrays
        setRecentTransactions(transactions);
        setShowAllStates(Array(transactions.length).fill(false)); // Initialize with false
      } else {
        const transactions = (data as Transaction[]).map((item) => [item]); // Single array mapped to array of arrays
        setRecentTransactions(transactions);
        setShowAllStates(Array(transactions.length).fill(false)); // Initialize with false
      }
    }
  }, [data]);

  // Check if there are any transactions
  const hasTransactions = recentTransactions.length > 0 && recentTransactions.some((transactionSet) => transactionSet.length > 0);

  if (!hasTransactions) {
    return null; // No rendering if there's no data
  }

  const toggleShowAll = (index: number) => {
    setShowAllStates((prev) => {
      const updatedShowAllStates = [...prev];
      updatedShowAllStates[index] = !updatedShowAllStates[index]; // Toggle the state for the clicked transaction set
      return updatedShowAllStates;
    });
  };

  return (
    <>
      {/* Toggle button for recent and upcoming transactions */}
      {/* <ButtonGroup sx={{ mb: 2 }}>
        <Button
          onClick={() => setSelectedTab("recent")}
          sx={{
            backgroundColor: selectedTab === "recent" ? colors.primary : "white",
            color: selectedTab === "recent" ? "white" : "primary.main",
            "&:hover": {
              backgroundColor: selectedTab === "recent" ? "primary.dark" : "grey.100",
            },
          }}
        >
          Recent Transactions
        </Button>
        <Button
          disabled // Upcoming is permanently disabled
          sx={{
            backgroundColor: "white",
            color: "primary.main",
          }}
        >
          Upcoming Transactions
        </Button>
      </ButtonGroup> */}

      <div className="min-w-full table-auto mt-2">
      {/* Table header (rendered only once) */}
      <div className="flex justify-between py-2 px-2 sm:px-6 lg:px-8">
        <div className="flex items-center sm:w-2/4 w-2/3 text-xs sm:text-base lg:text-sm text-gray-700 font-bold">
          Name
          <UnfoldMoreIcon fontSize="small" className="mr-1" />
        </div>
        <div className="w-1/4 text-center text-xs sm:text-base lg:text-sm text-gray-700 font-bold">
          Date
          <UnfoldMoreIcon fontSize="small" className="mr-1" />
        </div>
        <div className="w-1/4 text-center text-xs sm:text-base lg:text-sm text-gray-700 font-bold mr-3">
          Type
        </div>
        <div className="w-1/4 text-center text-xs sm:text-base lg:text-sm text-gray-700 font-bold">
          Amount
          <UnfoldMoreIcon fontSize="small" className="mr-1" />
        </div>
      </div>

      {/* Table data (mapped and rendered) */}
      <Box sx={{ padding: '0', maxHeight: '400px', overflowY: 'auto' }}>
        {recentTransactions.map((transactionSet, index) => (
          <div key={index}>
            {/* Show data, not headers multiple times */}
            {(showAllStates[index] ? transactionSet : transactionSet.slice(0, 20)).map((transaction) => (
              <InvestmentData
                key={transaction.id}
                title={transaction.holdingName || transaction.schemeName || transaction.name || ''}
                transactionDate={transaction.transactionDate || transaction.createdAt}
                createdAt={transaction.createdAt || ''}
                amount={transaction.amount || transaction.investedAmount || transaction.currentAmount || 0}
              />
            ))}

            {/* Button for "Show More" or "Show Less" */}
            {transactionSet.length > 20 && (
              <Button onClick={() => toggleShowAll(index)} sx={{ mt: 2 }}>
                {showAllStates[index] ? 'Show Less' : 'View All'}
              </Button>
            )}
          </div>
        ))}
      </Box>
    </div>
    </>
  );
};

interface InvestmentDataProps {
  title: string;
  transactionDate?: string;
  createdAt?: string;
  amount: number;
}

const InvestmentData: React.FC<InvestmentDataProps> = ({
  title,
  transactionDate,
  createdAt,
  amount,
}) => {


  const formatDate = (dateString?: string) => {
    if (!dateString) return "N/A";
    let date;
    if (dateString?.includes("T")) {
      date = new Date(dateString);
    } else {
      const [day, month, year] = dateString.split("/");
      date = new Date(Number(year), Number(month) - 1, Number(day));
    }
    if (isNaN(date.getTime())) return "Invalid Date"; 
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  

const displayDate = formatDate(transactionDate || createdAt);

return (
  <div className="flex flex-row items-center justify-between border border-gray-300 rounded-md my-4 max-h-auto py-4 md:h-20 hover:bg-lightBg px-2 sm:px-6 lg:px-8">
    <div className="flex items-center sm:w-1/2 w-2/3">
      <div className="bg-primary rounded-full sm:w-1/5 md:w-1/6 lg:w-[10%] h-10 sm:h-[52px] flex items-center justify-center text-white mr-2 text-lg sm:text-xl">
        {getInitials(title)}
      </div>
      <div className="flex-1">
        <h2 className="text-gray-700 text-xs sm:text-base lg:text-sm">{toCamelCase(title)}</h2>
      </div>
    </div>
    <div className="w-1/4 text-center">
      <p className="text-gray-700 text-xs sm:text-sm lg:text-md">{displayDate!=="-"?DateUtils.formatDate(displayDate):"-"}</p>
    </div>
    <div className="w-1/4 text-center">
      <p className="text-gray-700 text-xs sm:text-base lg:text-sm">{amount<0?"Sell":"Buy"}</p>
    </div>
    <div className="w-1/4 text-center">
      <p className="text-gray-700 text-xs sm:text-base lg:text-sm">{formatIndianCurrency(Math?.abs(amount))}</p>
    </div>
  </div>
);
};

export default Transactions;