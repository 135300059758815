import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { colors } from "../../constants/colors";

interface OrderRow {
  createdAt?: string;
  Type: string;
  SchemeName: string;
  Price: number;
  Status: string;
  orderStatus?: number; // Added optional orderStatus field
  orderType?:number;
  paymentStatus?:string;
}

interface OrderHistoryTableProps {
  headings: string[];
  rows: OrderRow[];
}

// Utility function to format date
const formatDate = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Utility function to map orderStatus to string
const getOrderStatusLabel = (status: number): string => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Accept";
    case 2:
      return "Reject";
    default:
      return "Unknown";
  }
};
// Utility function to decode HTML entities
const decodeHtmlEntities = (input: string): string => {
  if(input===" " || input==="" || input==="-"){
    return "-";
  }else{
  return input
    ?.replace(/#35;40;/g, " (") // Replace "&&#35;40;" with " ("
    ?.replace(/#35;41;/g, ")") // Replace "&&#35;41;" with ")"
    ?.replace(/&amp;/g, " ");    // Replace "&amp;" with "&"
  }
};

const selectType = (orderType: number) => {
  switch (orderType) {
    case 1:
      return "Buy";
    case 2:
      return "Buy";
    case 5:
      return "Sell";
    default:
      return "-";
  } 
}

const OrderHistoryTable: React.FC<OrderHistoryTableProps> = ({ headings, rows }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 400,
        overflowY: 'auto',
        overflowX: 'auto',
        boxShadow: 'none',
        border: '2px solid #ccc',
      }}
    >
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead
          sx={{
            backgroundColor: colors.lightBg,
            borderBottom: '2px solid #ccc',
            '& th': {
              backgroundColor: colors.lightBg,
              fontWeight: 600,
              color: colors.primary,
              fontSize: '16px',
              borderBottom: '2px solid #ccc',
            },
          }}
        >
          <TableRow>
            {headings.map((heading, index) => (
              <TableCell
                key={index}
                sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px', width: heading==="createdAt" ? "13%" : heading==="orderType" ? "7%" : heading==="schemeName" ? "30%" : heading==="amount" ? "10%" : heading==="paymentStatus" ? "20%" : heading==="orderStatus" ? "20%" : "auto" }}
              >
                {heading === "schemeName" ? "Scheme Name" : 
                heading === "orderType" ? "Type" : 
                 heading === "createdAt" ? "Transaction Date" : 
                 heading === "amount" ? "Amount" : 
                 heading === "paymentStatus"? "Payment Status" : heading === "orderStatus" ? "Order Status" : 
                 heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {headings.map((heading, cellIndex) => (
                <TableCell key={cellIndex} sx={{ fontSize: '16px' }}>
                  {heading === "createdAt"
                    ?row[heading as keyof OrderRow] as string
                    : heading === "paymentStatus"
                    ? decodeHtmlEntities(String(row[heading as keyof OrderRow] || "-"))
                    : heading === "orderStatus"
                    ? decodeHtmlEntities(String(row[heading as keyof OrderRow] || "-"))
                    :heading === "orderType"
                    ?selectType(row[heading as keyof OrderRow] as number)
                    :row[heading as keyof OrderRow]
                  }
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderHistoryTable;
