import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Container,
} from "@mui/material";
import HoldingsModalForm from "../modals/HoldingsModalForm";
import { Delete, Edit } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useUserData } from "../../hooks/useUserData";
import {
  deleteHoldingsData,
  editHoldingsData,
  HoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import SuccessfullModal from "../modals/SuccessfullModal";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { DateUtils } from "../../utils/FormatDate";

const PrivateEquityTableRowComponent = ({
  data,
  getInitials,
  modalMenuClick,
  fetchDataPrivateStock
}: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");

  const handleExpandClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const isArray = Array.isArray(data);
  function convertPercentage(value: any) {
    return parseFloat(value?.toFixed(2));
  }
  const handleOpen = (id: any, units: any, purchasePrice: any) => {
    setFields([
      { name: "units", label: "Units", type: "number" },
      { name: "purchasePrice", label: "Purchased Price", type: "number" },
    ]);
    setValues({
      units: Number(units),
      purchasePrice: Number(purchasePrice),
    });
    setID(id);
    handleModalOpen();
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        editHoldingsData({
          id: id,
          units: formData?.units,
          purchasePrice: formData?.purchasePrice,
          holdingType: holdingType,
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        fetchDataPrivateStock();
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        fetchDataPrivateStock();
        setMessage(`Scheme edided successfully.`);
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        fetchDataPrivateStock();
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      fetchDataPrivateStock();
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoading(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType: "PRIVATE_STOCK",
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        fetchDataPrivateStock();
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        fetchDataPrivateStock();
        setMessage(`Scheme deleted successfully.`);
      } else {
        setLoading(false);
        setOpen(true);
        fetchDataPrivateStock();
        setMessage(response?.payload.message);
      }
    } catch (error) {
      fetchDataPrivateStock();
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    setOpen(false);
    setMessage("");
  };

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          return (
            <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  sx={{
                    borderBottom: "2px solid #ccc",
                  }}
                  align="left"
                  className="min-w-[100px]"
                >
                  {toCamelCase(item?.symbol || "-")}
                </TableCell>
                <TableCell
                  sx={{borderBottom:"2px solid #ccc"}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {Number(item?.units) || "-"}
                </TableCell>
                <TableCell
                  sx={{borderBottom:"2px solid #ccc"}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.purchasePrice)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  sx={{borderBottom:"2px solid #ccc"}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.amount)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  sx={{borderBottom:"2px solid #ccc"}}
                  align="center"
                  className="min-w-[100px]"
                >
                    {item?.transactionDate ? DateUtils.formatDate(item?.transactionDate) : "-"}
                </TableCell>
                <TableCell
                  sx={{borderBottom:"2px solid #ccc"}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.currentPrice)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  sx={{borderBottom:"2px solid #ccc"}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.currentAmount)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{    
                    height: "100%",    
                    verticalAlign: "middle",            
                    borderBottom: "2px solid #ccc",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                  <IconButton>
                    <Edit
                      onClick={() =>
                        handleOpen(item?.id, item?.units, item?.purchasePrice)
                      }
                    />
                  </IconButton>
                  <IconButton>
                    <Delete
                      onClick={() =>
                        handleConfirmModalOpen(item?.id, toCamelCase(item?.symbol))
                      }
                    />
                  </IconButton>
                  </div>
                </TableCell>
              </TableRow>
              {/* <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow> */}
            </React.Fragment>
          );
        })}
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType="PRIVATE_STOCK"
          heading="Private Stock Transaction"
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default PrivateEquityTableRowComponent;
