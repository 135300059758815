import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Text, Button, Input, Heading, Img } from "../../components";
import VerificationModal from "../../components/modals/VerificationModal";
import Header from "../../components/Header/Header";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { colors } from "../../constants/colors";
import Footer from "../../components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SignUp } from "../../models/signup.model";
import { addPassword, signUp } from "../../redux/auth/authSlice";
import toast from "react-hot-toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../components/Loader/Loader";
import { FormInput } from "../../components/FormInput/FormInput";
import VerifyButton from "../../components/VerifyButton/VerifyButton";
import { panVerify } from "../../redux/verification_aadhar&pan/verificationSlice";
import { Button as Btn } from "@mui/material";
import { cursorTo } from "readline";
import { addKYC } from "../../redux/kyc/kycSlice";
import { setUserType } from "../../redux/user/userSlice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const signUpSchema = yup.object({
  firstName: yup.string().required("Full name is required."),
  // lastName: yup.string().required("Last Name is required."),
  email: yup.string().email().required("Email is required."),
  mobile: yup.string().required("Phone number is required.").length(13, "Phone Number should be 10 digit"),
  pancardNumber: yup
    .string()
    .required("PAN card number is required")
    .matches(/^([A-Z]{5}[0-9]{4}[A-Z]{1})+$/, "Enter valid PAN Number"),
});

const createPasswordSchema = yup.object({
  // userName: yup.string().required("Username is required."),
  password: yup
    .string()
    .required("Password is required.")
    .min(
      8,
      "Password is too short - should be 8 chars minimum with one uppercase, one lowercase and one special character"
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/~`])[A-Za-z\d!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/~`]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Confirm Password is required."),
});

const signUpValues = {
  firstName: "",
  // lastName: "",
  email: "",
  mobile: "",
  pancardNumber: "",
};

export default function Register() {
  const dispatch = useDispatch<any>();
  const panFormRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [signUpData, setSignUpData] = useState<SignUp>();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const accessToken = useSelector((state: any) => state?.auth?.accessToken);
  const refreshToken = useSelector((state: any) => state?.auth?.refreshToken);
  const addedPassword = useSelector((state: any) => state?.auth?.addedPassword);
  const signUpStatus = useSelector((state: any) => state?.auth?.signUpStatus);
  const [isPanTypeValid, setIsPanTypeValid] = useState(false);
  const location = useLocation(); // Get current location
  const currentPath = location.pathname; // Extract pathname
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [verifiedDetails, setVerifiedDetails] = useState({
    pancardNumber: "",
    pancard: "",
    isPanVerified: false,
  });
  const [isPanVerified, setIsPanVerified] = useState(false);
  const [userIDOpen, setUserIDOpen] = useState(false);
  const [Pan, setPan] = useState("");
  const [UserData,setUserData]=useState<Number>(-1);
  let createPasswordValues = {
    // userName: signUpValues.email || "",
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    if (accessToken && refreshToken) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      handleSuccess();
    }
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (addedPassword) {
      toast.success("Password created successfully!");
    }
  }, [addedPassword]);

  useEffect(() => {
    if (signUpStatus === 200) {
      toast.success(
        "OTP has been sent to your email address as well as phone number."
      );
    }
  }, [signUpStatus]);

  const validatePanType = (panNumber: string) => {
    if (panNumber.length !== 10) {
      // Don't validate until the PAN number is fully entered
      return true;
    }

    const fourthChar = panNumber.charAt(3).toUpperCase();
    const individualTypes = ["P"];
    const nonIndividualTypes = ["A", "C", "G", "L", "B", "F", "H", "J", "T"];

    // if (currentPath === "/onboarding") {
    //   if (!individualTypes?.includes(fourthChar)) {
    //     setDialogMessage("Invalid PAN type. Only individual PAN types are allowed (P).");
    //     setIsDialogOpen(true);
    //     return false;
    //   }
    //   return true;
    // } else if (currentPath === "/nonIndividual") {
    //   if (!nonIndividualTypes?.includes(fourthChar)) {
    //     setDialogMessage("Invalid PAN type. Only non-individual PAN types are allowed (A, B, C, F, G, H, J, L, T).");
    //     setIsDialogOpen(true);
    //     return false;
    //   }
    //   return true;
    // }
    if(individualTypes?.includes(fourthChar)) {
      setUserData(1);
      return true;
    }else if(nonIndividualTypes?.includes(fourthChar)){
      setUserData(5);
      return true;
    }

    setDialogMessage("Invalid PAN number.");
    setIsDialogOpen(true);
    return false;
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handlePanNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: any,
    setFieldValue: any
  ) => {
    const newValue = e.target.value.toUpperCase();
    handleChange(e);
    setFieldValue("pancardNumber", newValue);

    // Validate PAN type based on 4th character
    const isValid = validatePanType(newValue);
    setIsPanTypeValid(isValid);
  };

  const handleSuccess = () => {
    setOpen(false);
    setIsVerified(true);
    toast.success("Account created successfully!");
  };

  const validateConfirmPassword = (pass1: string, pass2: string) => {
    if (pass1 && pass2) {
      if (pass1 !== pass2) {
        return toast.error("Passwords do not match");
      }
    }
  };

  const handleSignup = async (values: SignUp) => {
    setLoading(true);
    setSignUpData(values);
    const response = await dispatch(signUp(values));
    if (response?.error?.message) {
      setLoading(false);
      toast.error(response?.payload?.message ?? "Something went wrong!");
      return;
    }
    console.log(response, response.payload, "response.payload");
    handleOpen();
    setLoading(false);
    // navigate("/entity");
  };

  const handleVerifyPAN = async () => {
    console.log(
      panFormRef.current?.values?.pancardNumber,
      "panFormRef.current?.values?.pancardNumber"
    );
    try {
      const payload = {
        pancardNumber: panFormRef.current?.values?.pancardNumber,
      };
      if (!payload?.pancardNumber) {
        setDialogMessage("Please enter all the details");
        setIsDialogOpen(true);
        return;
      }

      setLoading(true);
      const response = await dispatch(panVerify(payload));

      if (response?.error?.message) {
        setDialogMessage(response?.payload?.message);
        setIsDialogOpen(true);
        return;
      }

      setIsPanVerified(true);
      setPan(panFormRef.current?.values?.pancardNumber);
      toast.success("PAN verified successfully"); // You can keep success toast if needed
    } catch (error) {
      console.error("Error:", error);
      setDialogMessage("Failed to verify PAN");
      setIsDialogOpen(true);
    } finally {
      setLoading(false);
    }
  };



  const handleCloseUser = () => {
    localStorage.clear();
    navigate("/");
    setUserIDOpen(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="flex flex-col items-center justify-start w-full bg-white">
        <div className="flex flex-col items-center justify-start w-full px-5 py-8 md:px-14 md:py-12 bg-primary shadow-xs min-h-screen">
          <div className="flex flex-col items-start justify-start w-full gap-[31px] max-w-[962px]">
            <Heading
              as="h1"
              className="text-center text-white text-lg md:text-3xl"
            >
             {isVerified ? "Set Your Password" : "Create Your Account"}
            </Heading>
            {isVerified ? (
              <>
                <Formik
                  initialValues={createPasswordValues}
                  validationSchema={createPasswordSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    if (!signUpData?.email) {
                      toast.error("Something went wrong!");
                      return;
                    }
                    dispatch(
                      addPassword({
                        userName: signUpData?.email,
                        password: values.password,
                      })
                    );
                    setUserIDOpen(true);
                    const formData = new FormData();
                    formData.append("pancardNumber", Pan);
                    formData.append("isNominee", "0");
                    formData.append("forPan", "1");
                    const response = dispatch(addKYC(formData));
                    if (response?.error?.message) {
                      toast.error("Failed to add PAN Details");
                      return;
                    }
                    const res = dispatch(setUserType({userType:Number(UserData)}));
                    if (res?.error?.message) {
                      toast.error("Failed to add PAN Details");
                      return;
                      }                    
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    setFieldValue,
                    /* and other goodies */
                  }) => {                    
                    const allFieldsFilled = !!(
                      values.password &&
                      values.confirmPassword &&
                      signUpData?.email &&
                      isValid
                    );
                    return(
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (!isValid && errors.password) {
                            toast.error(errors.password);
                            return;
                          } else if (!isValid && errors.confirmPassword) {
                            toast.error(errors.confirmPassword);
                            return;
                          }
                          handleSubmit();
                        }}
                        className="w-full flex flex-col gap-[31px]"
                      >
                        <div className="flex flex-row justify-start items-center w-full gap-[42px]">
                          <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                            <Text
                              size="s"
                              as="p"
                              className="text-center text-white text-sm md:text-lg"
                            >
                              User email
                            </Text>
                            <Input
                              size="sm"
                              name="userName"
                              type="text"
                              // onChange={handleChange("userName")}
                              disabled
                              value={signUpData?.email}
                              className="w-full bg-lightGrey"
                            />
                          </div>
                          <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                            <Text
                              size="s"
                              as="p"
                              className="text-center text-white text-sm md:text-lg"
                            >
                              Password
                            </Text>
                            <Input
                              size="sm"
                              name="password"
                              type={passwordVisible ? "text" : "password"}
                              onChange={handleChange("password")}
                              suffix={
                                <IconButton
                                  onClick={() =>
                                    setPasswordVisible(!passwordVisible)
                                  }
                                >
                                  {passwordVisible ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              }
                              value={values.password}
                              className="w-full bg-white"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col md:flex-row justify-start items-center w-full gap-[42px]">
                          <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                            <Text
                              size="s"
                              as="p"
                              className="text-center text-white text-sm md:text-lg"
                            >
                              Confirm Password
                            </Text>
                            <Input
                              size="sm"
                              name="confirmPassword"
                              type="password"
                              onChange={handleChange("confirmPassword")}
                              value={values.confirmPassword}
                              className="w-full bg-white"
                            />
                          </div>
                          <Text
                            as="xs"
                            className="text-lightGrey text-sm max-w-[460px] leading-5 md:mt-8"
                          >
                            A combination of uppercase letters, lowercase
                            letters,numbers, and symbols. At least 8 characters
                            long but 12 or more is better.
                          </Text>
                        </div>
                        <Button
                          className={`w-full md:w-48 font-semibold text-white bg-darkGrey border border-white whitespace-nowrap  transition-all ${allFieldsFilled?"hover:bg-secondary":""}`}
                          color={colors.darkGrey}
                          variant="fill"
                          type="submit"
                          style={{
                            opacity: allFieldsFilled ? 1 : 0.5,
                            cursor: allFieldsFilled ? "pointer" : "not-allowed",
                          }}
                          disabled={!allFieldsFilled}
                        >
                          <span className="text-sm md:text-lg">Submit</span>
                        </Button>
                      </form>
                    </>
                    )
                  }}
                </Formik>
                <Dialog
                  open={userIDOpen}
                  // className="flex flex-col items-center justify-center mx-auto w-full max-w-[650px] bg-white rounded-[18px]"
                  onClose={handleCloseUser}
                  sx={{style}}
                >
                  <DialogContent>
                    <Typography variant="h5" component="p" gutterBottom>
                      User ID: {localStorage.getItem("userId")}
                    </Typography>
                    <Typography variant="body1" component="p">
                      User ID and password created successfully. Use User ID and password to login to AumSampann.
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                  }}>
                    <button onClick={handleCloseUser} className="text-white border rounded bg-primary hover:bg-secondary cursor-pointer px-4 py-2">
                      OK
                    </button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <>
                <Formik
                  initialValues={signUpValues}
                  validationSchema={signUpSchema}
                  innerRef={panFormRef}
                  onSubmit={(values) => {
                    console.log("first");
                    handleSignup(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit} className="w-full">
                      <div className="flex flex-row justify-start w-full">
                        <div className="flex flex-col items-start justify-start w-full gap-[34px]">
                          <div className="flex flex-row justify-start w-full gap-[42px]">
                            <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                              <Text
                                size="s"
                                as="p"
                                className="text-center text-white text-sm md:text-lg"
                              >
                                Email ID
                              </Text>
                              <Input
                                size="sm"
                                name="email"
                                type="email"
                                onChange={handleChange("email")}
                                value={values.email}
                                className="w-full bg-white"
                                error={errors.email}
                                touched={touched.email}
                              />
                            </div>
                            <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                              <Text
                                size="s"
                                as="p"
                                className="text-center text-white text-sm md:text-lg"
                              >
                                Phone
                              </Text>
                              <Input
                                size="sm"
                                name="mobile"
                                type="phone"
                                onChange={(e: any) => {
                                  console.log(e, "mobile number");
                                  setFieldValue("mobile", e);
                                }}
                                value={values.mobile}
                                className="w-full bg-white"
                                error={errors.mobile}
                                touched={touched.mobile}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row justify-start items-start w-full gap-[42px]">
                            <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                              <Text
                                size="s"
                                as="p"
                                className="text-center text-white text-sm md:text-lg"
                              >
                                Full name as per PAN
                              </Text>
                              <Input
                                size="sm"
                                name="firstName"
                                type="text"
                                onChange={handleChange("firstName")}
                                value={values.firstName}
                                className="w-full bg-white"
                                error={errors.firstName}
                                touched={touched.firstName}
                              />
                            </div>
                            <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                              <Text
                                size="s"
                                as="p"
                                className="text-center text-white text-sm md:text-lg"
                              >
                                PAN Number <span className="text-[16px] text-white">(Please click "Verify" before proceeding further)</span>
                              </Text>
                              <Input
                                // label="PAN Number *"
                                size="sm"
                                type="text"
                                name="pancardNumber"
                                id="pancardNumber"
                                maxLength={10}
                                className="w-full bg-white"
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handlePanNumberChange(
                                    e,
                                    handleChange,
                                    setFieldValue
                                  )
                                }
                                placeholder={
                                  verifiedDetails?.pancardNumber
                                    ? `**** **** ${verifiedDetails?.pancardNumber}`
                                    : ""
                                }
                                disabled={isPanVerified}
                                value={values?.pancardNumber}
                                error={errors?.pancardNumber}
                                touched={touched?.pancardNumber}
                                suffix={
                                  <VerifyButton
                                    isVerified={isPanVerified}
                                    onClick={handleVerifyPAN}
                                  />
                                }
                              />
                              {!isPanTypeValid &&
                                values?.pancardNumber.length >= 4 && (
                                  <p className="error-text">
                                    PAN type does not match the required type
                                    for this section.
                                  </p>
                                )}
                            </div>
                          </div>
                          <FormControlLabel
                            required
                            control={<Checkbox sx={{ color: colors.white }} />}
                            label="I am aware that my contact details will be used for all future communications by AUM Sampann."
                            sx={{ color: colors.white }}
                            className="text-sm md:text-lg"
                          />
                          <a href="#" style={{ color: "white" }}>
                            By proceeding, you agree to our T&C. Plese click
                            here to read them.
                          </a>
                          <div className="flex flex-row justify-start">
                            <Button
                              className={`w-full font-semibold text-white bg-darkGrey border border-white whitespace-nowrap ${
                                isPanVerified
                                  ? "hover:bg-secondary"
                                  : "cursor-not-allowed opacity-50"
                              }  transition-all mr-7`}
                              style={{
                                cursor: isPanVerified
                                  ? "pointer"
                                  : "not-allowed",
                              }}
                              color={colors.darkGrey}
                              type="submit"
                              variant="fill"
                              disabled={!isPanVerified}
                            >
                              <span className="text-sm md:text-lg">
                                Verify Email & Phone Number
                              </span>
                            </Button>
                            <Button
                              className="w-full font-semibold text-white bg-darkGrey border border-white hover:bg-secondary transition-all mr-4"
                              color={colors.darkGrey}
                              variant="fill"
                              onClick={() => navigate("/")}
                            >
                              <span className="text-sm md:text-lg">
                                Back To Home
                              </span>
                            </Button>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              sx={{ backgroundColor: colors.white }}
                            >
                              <VerificationModal
                                onClose={handleClose}
                                onSuccess={handleSuccess}
                                signupValues={signUpData}
                                handleSignup={() => handleSignup(values)}
                              />
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
                <Dialog
                  open={isDialogOpen}
                  onClose={handleCloseDialog}
                  sx={{
                    "& .MuiDialog-paper": {
                      width: "400px", // Set the width
                      height: "200px", // Set the height
                      maxWidth: "100%", // Ensure it doesn't exceed the viewport width
                    },
                  }}
                >
                  <DialogTitle
                    sx={{ fontWeight: "bold", color: colors.primary }}
                  >
                    Error
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText sx={{ color: "text.secondary" }}>
                      {dialogMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Btn
                      onClick={handleCloseDialog}
                      variant="contained"
                      sx={{
                        backgroundColor: colors.primary,
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                        },
                      }}
                    >
                      OK
                    </Btn>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
