import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AHServices from './assetServices';
import {AssetHoldings , AssetHoldingsData,AssetHoldingsMFData}from '../../models/assetHoldings.model';

export const MFAHInfo = createAsyncThunk(
    "funds/getMFHoldings", // Unique action type for MF holdings
    async (_, thunkAPI: any) => {
        try {
            // if (!data) {
            //     throw new Error("Invalid parameter provided");
            // }
            // console.log('Getting data for');
            const response = await AHServices.getAllMFHoldings();
            return response.holdings;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);


export const getUnrealisedMFInfo = createAsyncThunk(
    "funds/getUnrealisedReport", // Unique action type for FD holdings
    async (data: AssetHoldingsMFData, thunkAPI: any) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            console.log('Getting data for', data);
            const response = await AHServices.getUnrealisedMFHoldings(data);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const FDAHInfo = createAsyncThunk(
    "funds/getFDHoldings", // Unique action type for FD holdings
    async (data: any, thunkAPI: any) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            console.log('Getting data for', data);
            const response = await AHServices.getAllFDHoldings(data);
            return response.holdings;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const HoldingsData = createAsyncThunk(
    "user/getUserHoldings", // Unique action type for FD holdings
    async (data: any, thunkAPI: any) => {
        try {
            if (!data) {
                throw new Error("Invalid parameter provided");
            }
            console.log('Getting data for', data);
            const response = await AHServices.getHoldingsData(data);
            return response.holdings;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const uploadHoldingsData = createAsyncThunk(
    "user/uploadHoldingsv3", // Unique action type for FD holdings
    async (formData: any, thunkAPI: any) => {
        try {
            if (!formData) {
                throw new Error("Invalid parameter provided");
            }
            console.log('Getting data for', formData);
            const response = await AHServices.uploadHoldingsData(formData);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const addSingleHoldingsData = createAsyncThunk(
    "user/uploadHoldings", // Unique action type for FD holdings
    async (formData: any, thunkAPI: any) => {
        try {
            if (!formData) {
                throw new Error("Invalid parameter provided");
            }
            console.log('Getting data for', formData);
            const response = await AHServices.addSingleHoldingsData(formData);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const editHoldingsData = createAsyncThunk(
    "user/editHoldingsv2", // Unique action type for FD holdings
    async (formData: any, thunkAPI: any) => {
        try {
            if (!formData) {
                throw new Error("Invalid parameter provided");
            }
            console.log('Getting data for', formData);
            const response = await AHServices.editHoldingsData(formData);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const editHoldingsData2 = createAsyncThunk(
    "user/editHoldings", // Unique action type for FD holdings
    async (formData: any, thunkAPI: any) => {
        try {
            if (!formData) {
                throw new Error("Invalid parameter provided");
            }
            console.log('Getting data for', formData);
            const response = await AHServices.editHoldingsData2(formData);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const deleteHoldingsData = createAsyncThunk(
    "user/deleteHoldings", // Unique action type for FD holdings
    async (formData: any, thunkAPI: any) => {
        try {
            if (!formData) {
                throw new Error("Invalid parameter provided");
            }
            console.log('Getting data for', formData);
            const response = await AHServices.deleteHoldingsData(formData);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);
export const getSchemes = createAsyncThunk(
    "admin/getSchemeName",
    async (data: any, thunkAPI) => {
      try {
         const response = await AHServices.getSchemes(data);
         return response.data
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  

const initialState = {
    AllMFHoldings: [],
    AllFDHoldings: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    totalPages:0,
    pageIndex:1,
    message: "",
};

export const assetSlice = createSlice({
    name: "asset",
    initialState: initialState,
    reducers: {
        resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(MFAHInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(MFAHInfo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFHoldings = action.payload;
            })
            .addCase(MFAHInfo.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(uploadHoldingsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(uploadHoldingsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFHoldings = action.payload;
            })
            .addCase(uploadHoldingsData.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(FDAHInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(FDAHInfo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllFDHoldings = action.payload;
            })
            .addCase(FDAHInfo.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(HoldingsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(HoldingsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllFDHoldings = action.payload;
            })
            .addCase(HoldingsData.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getUnrealisedMFInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUnrealisedMFInfo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFHoldings = action.payload;
            })
            .addCase(getUnrealisedMFInfo.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(deleteHoldingsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteHoldingsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFHoldings = action.payload;
            })
            .addCase(deleteHoldingsData.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(editHoldingsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editHoldingsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFHoldings = action.payload;
            })
            .addCase(editHoldingsData.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(editHoldingsData2.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editHoldingsData2.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFHoldings = action.payload;
            })
            .addCase(editHoldingsData2.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(addSingleHoldingsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addSingleHoldingsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.AllMFHoldings = action.payload;
            })
            .addCase(addSingleHoldingsData.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getSchemes.pending, (state) => {
                state.isLoading = true;
              })
              .addCase(getSchemes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.AllMFHoldings = action.payload.fdList; // Extract users from fdList
                state.totalPages = action.payload.totalPages;
                state.pageIndex = action.payload.pageIndex;
              })
              .addCase(getSchemes.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
              })
    },
});

export const { resetState } = assetSlice.actions;
export default assetSlice.reducer;
