import CloseIcon from "@mui/icons-material/DeleteOutline";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import add from "../../assets/icons/AddButton.svg";
import { Button } from "../../components";
import { colors } from "../../constants/colors";
import { useUserData } from "../../hooks/useUserData";
import { addNomineeByV2 } from "../../redux/nominee/nomineeSlice";
import { getUserById, updateProfile } from "../../redux/user/userSlice";
import convertFileToBase64 from "../../utils/fileConverter";
import { CustomFileinput } from "../FileInput/CustomFileinput";
import FormDatePicker from "../FormDatePicker/FormDatePicker";
import { FormInput } from "../FormInput/FormInput";
import FormSelect from "../FormSelect/FormSelect";
import ConfirmationModal from "../modals/ConfirmationModal2";
import { getStatesOfCountry } from "country-state-city/lib/state";
import { City, State } from "country-state-city";
import { parseJSON } from "date-fns";
import { IndianStates, getKeyByValue } from "../../enums/states";

const relationshipOptions = [
  { label: "AUNT", value: 1 },
  { label: "BROTHER-IN-LAW", value: 2 },
  { label: "BROTHER", value: 3 },
  { label: "DAUGHTER", value: 4 },
  { label: "DAUGHTER-IN-LAW", value: 5 },
  { label: "FATHER", value: 6 },
  { label: "FATHER-IN-LAW", value: 7 },
  { label: "GRAND DAUGHTER", value: 8 },
  { label: "GRAND FATHER", value: 9 },
  { label: "GRAND MOTHER", value: 10 },
  { label: "GRAND SON", value: 11 },
  { label: "MOTHER-IN-LAW", value: 12 },
  { label: "MOTHER", value: 13 },
  { label: "NEPHEW", value: 14 },
  { label: "NIECE", value: 15 },
  { label: "SISTER", value: 16 },
  { label: "SISTER-IN-LAW", value: 17 },
  { label: "SON", value: 18 },
  { label: "SON-IN-LAW", value: 19 },
  { label: "SPOUSE", value: 20 },
  { label: "UNCLE", value: 21 },
  { label: "OTHERS", value: 22 },
  { label: "COURT APPOINTED LEGAL GUARDIAN", value: 23 },
];

const identifierOptions = [
  { label: "PAN", value: 1 },
  { label: "Driving Licence", value: 2 },
  { label: "Last 4 digits of Aadhaar", value: 3 },
];

interface Guardian {}

interface Nominee {
  name?: string;
  mobile?: string;
  email?: string;
  relation?: number;
  identifier?: number;
  identifierData?: string;
  pan?: string;
  licence?: String;
  dateOfBirth?: string;
  percentage?: string;
  pancardNumber?: string;
  address1?: string;
  address2?: string;
  pincode?: string;
  city?: string;
  state?: string;
  country?: string;
  aadhaar?: string;
  aadharMobileNumber?: string;
  isMinor?: boolean;
  // addressProof?:any;
  guardian?: {
    name?: string;
    // mobileNumber?:string;
    pancardNumber?: string;
    relation?: string;
  };
  isMinorAddress?: boolean;
  isAddress?: boolean;
}

interface AddNomineesProps {
  onSubmit?: any;
  data?: any;
  Border?: boolean;
  submitButton?: boolean;
  removeParent?: boolean;
  xs: number;
  initialNomineeCount: number;
  nominee?: any;
}

const calculateAge = (dateOfBirth: any): number => {
  const [day, month, year] = dateOfBirth.split("/").map(Number);
  const birthDate = new Date(year, month - 1, day);
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const AddNominees: React.FC<AddNomineesProps> = ({
  onSubmit,
  data,
  Border,
  xs,
  initialNomineeCount,
  submitButton,
  removeParent,
  nominee,
}) => {
  const userdata = useUserData();
  const registeredNominees = userdata?.nominee;
  const kyc = userdata?.kyc;
  const [newNominees, setNewNominees] = useState<Nominee[]>([]);
  const dispatch = useDispatch<any>();
  const [open, setOpen] = useState(0);
  const [componentState, setComponentState] = useState<{
    [key: number]: { identifier: string; identifierData: string };
  }>({});
  const [cities, setCities] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [city, setCity] = useState<string[]>([]);
  const [state, setState] = useState<string[]>([]);
  const [countries, setCountries] = useState<any[]>([{label:"India",value:"India"}]);
  const [errors, setErrors] = useState<{
    [key: number]: { [field: string]: string };
  }>({});
  const [showGuardianDetails, setShowGuardianDetails] = useState<any>(false);
  // const [addressChange, setAddressChange] = useState<{ [key: number]: boolean }>({});
  const [nameDisabled, setNameDisabled] = useState(true);
  // const [addresspincode, setAddresspincode] = useState(true);
  const [existingNomineeDetails, setExistingNomineeDetails] =
    useState<boolean>();

  useEffect(() => {
    window.scrollTo(0, 0);
    getStates();
    const existingNomineeData = nominee || [];
    if (
      (newNominees.length === 0 && initialNomineeCount > 0) ||
      existingNomineeData?.length > 0
    ) {
      let initialNominees: Nominee[];

      if (existingNomineeData?.length > 0) {
        initialNominees = existingNomineeData.map((data: any) => ({
          name: data.name,
          mobileNumber: data.mobileNumber,
          email: data.email,
          relation: data.relation,
          dateOfBirth: new Date(data.dateOfBirth).toISOString().split("T")[0], // Convert timestamp to YYYY-MM-DD
          percentage: data.percentage,
          // nomineeAddress: data.nomineeAddress,
          pincode: data.pincode,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          country: data.country,
          aadharNumber: data.aadharNumber,
          aadharMobileNumber: data.aadharMobileNumber,
          isMinor: data.isMinor === 1,
          guardian: {
            ...JSON?.parse(data?.guardianDetails),
          },
          pancardNumber: data.pancardNumber,
        }));
        setExistingNomineeDetails(true);
      } else {
        initialNominees = Array.from(
          { length: initialNomineeCount },
          () => ({})
        );
      }
      setNewNominees(initialNominees);
    }
  }, [initialNomineeCount]);

  const getStates = () => {
    setStates(
      State.getStatesOfCountry("IN").map((state) => {
        return {
          label: state.name,
          value: state.name,
          isoCode: state?.isoCode,
          code: IndianStates[state.name],
        };
      })
    );
  };

  const address1 = userdata?.address1 || "";
  const address2 = userdata?.address2 || "";
  const address3 = userdata?.address3 || "";
  const defaultAddress = `${address1} ${address2} ${address3}`.trim();
  const defaultPincode = userdata?.pincode;

  const getLabel = (identifier: any) => {
    const value = Number(identifier);
    switch (value) {
      case 1:
        return "PAN";
      case 2:
        return "Driving Licence";
      case 3:
        return "Last 4 digits of Aadhaar";
      default:
        return "Identifier Details";
    }
  };

  const handleDropdownChange = (index: number, value: string) => {
    setComponentState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        identifier: value,
        identifierData: "",
      },
    }));
  };

  // console.log(componentState,"componentState");

  const handleInputChange = async (
    index: number,
    e: { name: string; value: any; files?: FileList }
  ) => {
    const { name, value, files } = e;
    let inputValue = value;
    if (files && files[0]) {
      try {
        inputValue = await convertFileToBase64(files[0]); // Ensure convertFileToBase64 returns a base64 string
      } catch (error) {
        console.error("File conversion error:", error);
        return;
      }
    }
  
      setNewNominees((prevNominees: any) => {
        const updatedNominees = prevNominees.map((nominee: any, i: number) => {
          if (i === index) {
            if (name === "guardianName") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  name: inputValue,
                },
              };
            } else if (name === "guardianMobile") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  mobile: inputValue,
                },
              };
            } else if (name === "guardianPanCard") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  pancardNumber: inputValue,
                },
              };
            } else if (name === "guardianDateOfBirth") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  dateOfBirth: inputValue,
                },
              };
            } else if (name === "guardianEmail") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  email: inputValue,
                },
              };
            } else if (name === "guardianAddress1") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  address1: inputValue,
                },
              };
            } else if (name === "guardianAddress2") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  address2: inputValue,
                },
              };
            } else if (name === "guardianPincode") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  pincode: inputValue,
                },
              };
            } else if (name === "guardianCity") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  city: inputValue,
                },
              };
            } else if (name === "guardianState") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  state: inputValue,
                },
              };
            } else if (name === "guardianCountry") {
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  country: inputValue,
                },
              };
            } else if (name === "guardianRelation") {
              // Handle the new relationship field
              return {
                ...nominee,
                guardian: {
                  ...nominee.guardian,
                  relation: inputValue, // Update relationship value
                },
              };
            }else if (name === "identifierData") {
              setComponentState((prevState) => ({
                ...prevState,
                [index]: {
                  ...prevState[index],
                  [name]: inputValue,
                },
              }));
              if(componentState[index]?.identifier === "1"){
                return{
                  ...nominee,
                  pancardNumber: inputValue
                }
              }else{
                return{
                  ...nominee,
                  aadharNumber: inputValue
                }
              }
            }else {
              return {
                ...nominee,
                [name]: inputValue,
              };
            }
          }
          return nominee;
        });

        if (name === "dateOfBirth") {
          const age = calculateAge(updatedNominees[index].dateOfBirth);
          updatedNominees[index].isMinor = age < 18;
          setShowGuardianDetails((prevDetails: any) => ({
            ...prevDetails,
            [index]: age < 18,
          }));
        }

        return updatedNominees;
      });
    
    // Clear the error for this field
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[index]?.[name]) {
        delete updatedErrors[index][name];
        if (Object.keys(updatedErrors[index]).length === 0) {
          delete updatedErrors[index];
        }
      }
      return updatedErrors;
    });
    // console.log("Updated Nominees:", newNominees);
  };

  const handleRemoveNominee = (index: number) => {
    const updatedNominees = newNominees.filter((_, i) => i !== index);
    setNewNominees(updatedNominees);
    const newErrors = { ...errors };
    delete newErrors[index];
    setErrors(newErrors);

    setShowGuardianDetails((prevDetails: any) => {
      const { [index]: _, ...remainingDetails } = prevDetails;
      return remainingDetails;
    });
  };

  const validate = () => {
    const newErrors: { [key: number]: { [field: string]: string } } = {};
    const fullName = `${userdata.firstName}${userdata.lastName}`;

    const kycPancardNumbers: string[] = [];
    const kycAadhaarNumbers: string[] = [];

    if (userdata.kyc && typeof userdata.kyc === "object") {
      const { pancardNumber, aadharNumber } = userdata.kyc;
      if (pancardNumber) kycPancardNumbers.push(pancardNumber);
      if (aadharNumber) kycAadhaarNumbers.push(aadharNumber);
    }

    const calculateTotalPercentage = (nominees: Nominee[]) => {
      return nominees.reduce((total, nominee) => {
        if (nominee?.percentage) {
          const percentage = parseFloat(nominee.percentage.replace("%", ""));
          if (!isNaN(percentage)) {
            return total + percentage;
          }
        }
        return total;
      }, 0);
    };
    if (!existingNomineeDetails) {
      const totalPercentageDistribution =
        calculateTotalPercentage(registeredNominees) +
        calculateTotalPercentage(newNominees);

      newNominees.forEach((nominee, index) => {
        const nomineeErrors: { [field: string]: string } = {};

        if (!nominee.name) {
          nomineeErrors.name = "Full Name is required.";
        } else if (nominee.name === fullName) {
          nomineeErrors.name = `Nominee Name should be different from User Name, ${fullName}`;
        }
        if (!nominee.dateOfBirth) {
          nomineeErrors.dateOfBirth = "Date Of Birth is required.";
        }
        if (!nominee.relation || nominee.relation == undefined) {
          nomineeErrors.relation = "Relationship is required.";
        }
        if (nominee.isMinor) {
          if (!nominee.guardian?.name) {
            nomineeErrors.guardianName = "Guardian Name is required.";
          }
          if (!nominee.guardian?.pancardNumber) {
            nomineeErrors.guardianPanCard = "Guardian Pancard is required.";
          }
          if (
            !nominee.guardian?.relation ||
            nominee.guardian?.relation == undefined
          ) {
            nomineeErrors.guardianRelation =
              "Guardian Relationship is required.";
          }
        }

        if (
          (nominee.percentage ||
            nominee.percentage == undefined ||
            nominee.percentage == "") &&
          totalPercentageDistribution !== 100
        ) {
          nomineeErrors.percentage = "Total percentage should be 100%.";
        }

        if (Object.keys(nomineeErrors).length > 0) {
          newErrors[index] = nomineeErrors;
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSaveAndProceed = () => {
    if (existingNomineeDetails) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      if (validate()) {
        setIsModalVisible(true);
      }
    }
  };

  const handleModalConfirm = async () => {
    setIsModalVisible(false);

    try {
      const NewNomineeData = {
        nominee: newNominees.map((nominee) => ({
          ...nominee,
          guardian: {
            name: nominee.guardian?.name || "",
            pancardNumber: nominee?.guardian?.pancardNumber || "",
            relation: nominee?.guardian?.relation || "",
          },
        })),
      };
      console.log(NewNomineeData, "NewNomineeData");
      const response = await dispatch(updateProfile(NewNomineeData));
      if (response.payload?.statusCode === 200) {
        toast.success("Nominee added successfully");
        dispatch(addNomineeByV2(NewNomineeData));
        const fetchUserPreferences = async () => {
          try {
            const response = await dispatch(getUserById({}));
            const userData = response.payload.data[0];
            setExistingNomineeDetails(userData?.nominee);
            console.log(userData?.nominee, "...1...");
          } catch (error) {
            console.error("Failed to fetch user preferences:", error);
          }
        };

        fetchUserPreferences();
        onSubmit();
        setNewNominees([]);
        return { success: true };
      } else if (response.payload?.success === 400) {
        toast.error("Nominee already exists.");
        setNewNominees([]);
      } else {
        toast.error("Failed to add nominee. Please try again later.");
      }
    } catch (error) {
      console.error("Error adding nominee:", error);
      toast.error("Failed to add nominee. Please try again later.");
    }
  };

  const handleAddNominee = () => {
    if (registeredNominees?.length + newNominees?.length === 3) {
      toast.error(`You can add maximum 3 Nominee's`);
      return;
    }
    setNewNominees([
      ...newNominees,
      {
        name: "",
        relation: 0,
        dateOfBirth: "",
        percentage: "",
        guardian: {
          name: "",
          pancardNumber: "",
          relation: "",
        },
        isAddress: false,
        isMinorAddress: false,
      },
    ]);
  };

  const handleNameDisabled = () => {
    setNameDisabled(!nameDisabled);
  };

  const calAge = (timestamp: any) => {
    const birthDate = new Date(timestamp);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const handleCityChange = (index: number, value: string) => {
    const updatedCities = [...cities];
    updatedCities[index] = value; // Update the specific index with the new value
    setCities(updatedCities);
  };

  const handleStateChange = (index: number, value: string) => {
    const updatedStates = [...states];
    updatedStates[index] = value; // Update the specific index with the new value
    setState(updatedStates);
  };

  const getCities = (index: any, state: any, city: any) => {
    const currentState = getStatesOfCountry("IN").filter((item: any) => {
      return item.name === state;
    })[0];
    const cities = [];

    cities.push({
      label: city[0]?.Block,
      value: city[0]?.Block,
    });
    // Otherwise, map all the cities based on the state's isoCode
    const stateCities = City.getCitiesOfState("IN", currentState?.isoCode).map(
      (x: any) => {
        return { label: x.name, value: x.name };
      }
    );
    cities.push(...stateCities); // Spread the array to add all cities

    // Set the cities data
    setCities(cities);
    // console.log(cities, "cities");
  };

  const fetchCityAndState = (type:string, pincode: string, index: any) => {
    if (!pincode || pincode.length !== 6) {
      return;
    }
    fetch(`https://api.postalpincode.in/pincode/${pincode}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, "data");
        if (data[0].Status === "Success") {
          const { PostOffice } = data[0];
          if (PostOffice.length > 0 && type === "nominee") {
            handleStateChange(index, PostOffice[0].State);
            getCities(index, PostOffice[0].State, PostOffice);
            handleCityChange(index, PostOffice[0].Block);
            handleInputChange(index, {
              name: "city",
              value: PostOffice[0].Block,
            });
            handleInputChange(index, {
              name: "state",
              value: PostOffice[0].State,
            });
            handleInputChange(index, { name: "country", value: "India" });
          }else if (PostOffice.length > 0 && type === "guardian") {
            handleStateChange(index,PostOffice[0].State);
            getCities(index, PostOffice[0].State, PostOffice);
            handleCityChange(index, PostOffice[0].Block);
            handleInputChange(index, {
              name: "guardianCity",
              value: PostOffice[0].Block,
            });
            handleInputChange(index, {
              name: "guardianState",
              value: PostOffice[0].State,
            });
            handleInputChange(index, { name: "guardianCountry", value: "India" });
          } else {
            toast("No data found for this pincode");
          }
        } else {
          toast("Invalid pincode");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };


  return (
    <div className="w-full">
      <div className="">
        {newNominees.map((nominee: any, index: any) => (
          <Box
            key={index}
            className={`${
              Border ? "border border-lightGrey rounded-lg p-4 mb-4 " : ""
            } mt-4`}
          >
            {index >= 1 && (
              <Divider sx={{ borderBottomWidth: 3, marginBottom: 4 }} />
            )}
            <div className="flex justify-between items-center mb-4">
              <Typography
                variant="h6"
                className="my-6 pb-4"
                style={{ color: colors.primary, fontWeight: "bold" }}
              >
                {!existingNomineeDetails ? `Nominee ${index + 1}` : `Nominee ${index + 1}`}
              </Typography>
              <div>
                {(newNominees.length > 1 || removeParent) &&
                  !existingNomineeDetails &&
                  index >= 0 && (
                    <span
                      onClick={() => setOpen(open === index ? -1 : index)}
                      className="cursor-pointer"
                    >
                      <EditIcon
                        sx={{
                          color: colors.secondary,
                          // backgroundColor: colors.white,
                          // borderRadius: "40px",
                          width: "2rem",
                          height: "2rem",
                          marginRight: 2,
                        }}
                      />
                    </span>
                  )}
                {(newNominees.length > 1 || removeParent) &&
                  !existingNomineeDetails &&
                  index >= 1 && (
                    <span
                      onClick={() => {
                        handleRemoveNominee(index);
                        setOpen(index - 1);
                      }}
                      className="cursor-pointer"
                    >
                      <CloseIcon
                        sx={{
                          color: colors.secondary,
                          // backgroundColor: colors.white,
                          // borderRadius: "40px",
                          width: "2rem",
                          height: "2rem",
                        }}
                      />
                    </span>
                  )}
              </div>
            </div>
            <Collapse in={open === index} timeout="auto" unmountOnExit>
              <Grid
                container
                spacing={2}
                className="flex justify-between items-center"
              >
                <Grid item xs={xs}>
                  <label className="text-lg text-darkGrey">Full Name *</label>
                  <FormInput
                    name="name"
                    value={nominee.name}
                    onChange={(e: any) => handleInputChange(index, e.target)}
                    placeholder="Enter full name"
                    className="mt-1"
                    style={{
                      marginBottom: "-6px", // Disable the default margin
                    }}
                    required
                    disabled={existingNomineeDetails}
                  />
                  {!!errors[index]?.name && errors[index]?.name && (
                    <span className="error-text">{errors[index]?.name}</span>
                  )}
                </Grid>

                <Grid item xs={xs}>
                  <FormSelect
                    label="Relationship *"
                    name="relation"
                    value={nominee.relation}
                    onChange={(e: any) => handleInputChange(index, e.target)}
                    options={relationshipOptions}
                    className="mt-1"
                    style={{
                      marginBottom: 0, // Disable the default margin
                    }}
                    disabled={existingNomineeDetails}
                  />
                  {!!errors[index]?.relation && errors[index]?.relation && (
                    <span className="error-text">
                      {errors[index]?.relation}
                    </span>
                  )}
                </Grid>

                <Grid item xs={xs}>
                  <FormSelect
                    label="Personal Identifier"
                    name="identifier"
                    value={componentState[index]?.identifier}
                    onChange={(e: any) =>
                      handleDropdownChange(index, e.target.value)
                    }
                    options={identifierOptions}
                    className="mt-1"
                    style={{
                      marginBottom: 0, // Disable the default margin
                    }}
                    disabled={existingNomineeDetails}
                  />
                </Grid>

                <Grid item xs={xs}>
                  <label className="text-lg text-darkGrey">
                    {componentState[index]?.identifier !== ""
                      ? getLabel(componentState[index]?.identifier)
                      : "Identifier Data"}
                  </label>
                  <FormInput
                    name="identifierData"
                    value={(componentState[index]?.identifierData)?.toUpperCase()}
                    onChange={(e: any) => handleInputChange(index, e.target)}
                    placeholder={`Enter ${getLabel(
                      componentState[index]?.identifier
                    )}`}
                    className="mt-1"
                    style={{
                      marginBottom: "-6px", // Disable the default margin
                    }}
                    disabled={existingNomineeDetails}
                  />
                </Grid>

                <Grid item xs={xs}>
                  <label className="text-lg text-darkGrey">Email Address</label>
                  <FormInput
                    name="email"
                    value={nominee.email}
                    onChange={(e: any) => handleInputChange(index, e.target)}
                    placeholder="Enter email"
                    className="mt-1"
                    style={{
                      marginBottom: "-6px", // Disable the default margin
                    }}
                    disabled={existingNomineeDetails}
                  />
                </Grid>

                <Grid item xs={xs}>
                  <label className="text-lg text-darkGrey">Mobile Number</label>
                  <FormInput
                    name="mobile"
                    value={nominee.mobile}
                    onChange={(e: any) => handleInputChange(index, e.target)}
                    placeholder="Enter mobile number"
                    className="mt-1"
                    style={{
                      marginBottom: "-6px", // Disable the default margin
                    }}
                    disabled={existingNomineeDetails}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => {
                            newNominees[index].isAddress =
                              !newNominees[index].isAddress;
                            setNewNominees([...newNominees]);
                          }}
                          checked={newNominees[index].isAddress}
                          disabled={existingNomineeDetails}
                        />
                      }
                      label="Same as my address"
                    />
                  </FormGroup>
                </Grid>
                <Collapse
                  in={!newNominees[index].isAddress}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid
                    container
                    spacing={0}
                    className="flex justify-between items-center"
                  >
                    <Grid item xs={xs} className="pl-4">
                      <label className="text-lg text-darkGrey">Address 1</label>
                      <FormInput
                        name="address1"
                        value={nominee.address1}
                        onChange={(e: any) =>
                          handleInputChange(index, e.target)
                        }
                        placeholder="Enter address1"
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>

                    <Grid item xs={xs} className="pl-4">
                      <label className="text-lg text-darkGrey">Address 2</label>
                      <FormInput
                        name="address2"
                        value={nominee.address2}
                        onChange={(e: any) =>
                          handleInputChange(index, e.target)
                        }
                        placeholder="Enter address2"
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>

                    <Grid item xs={xs} className="pl-4">
                      <label className="text-lg text-darkGrey">Pincode</label>
                      <FormInput
                        name="pincode"
                        value={nominee.pincode}
                        onChange={(e: any) => {
                          handleInputChange(index, e.target);
                          fetchCityAndState("nominee",e.target.value, index);
                        }}
                        placeholder="Enter pincode"
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>

                    <Grid item xs={xs} className="pl-4">
                      <label className="text-lg text-darkGrey">City</label>
                      <FormInput
                        // label="City"
                        name="city"
                        value={nominee.city}
                        onChange={(e: any) =>
                          handleInputChange(index, e.target)
                        }
                        placeholder="Enter city"
                        className="mt-1"
                        // options={cities}
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>

                    <Grid item xs={xs} className="pl-4">
                      <label className="text-lg text-darkGrey">State</label>
                      <FormInput
                        // label="State "
                        name="state"
                        value={nominee.state}
                        onChange={(e: any) =>
                          handleInputChange(index, e.target)
                        }
                        // options={states}
                        placeholder="Enter state"
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>

                    <Grid item xs={xs} className="pl-4">
                      <label className="text-lg text-darkGrey">Country</label>
                      <FormInput
                      // label="Country"
                        name="country"
                        value={nominee.country}
                        onChange={(e: any) =>
                          handleInputChange(index, e.target)
                        }
                      //  options={countries}
                        placeholder="Enter country"
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>
                  </Grid>
                </Collapse>

                <Grid item xs={xs}>
                  <label className="text-lg text-darkGrey">
                    Date Of Birth *
                  </label>
                  <FormDatePicker
                    label=""
                    name="dateOfBirth"
                    onChange={(date) => {
                      handleInputChange(index, {
                        name: "dateOfBirth",
                        value: date ? date.format("DD/MM/YYYY") : "",
                      });
                    }}
                    value={
                      nominee.dateOfBirth
                        ? dayjs(nominee.dateOfBirth) // Convert the timestamp to a dayjs object
                        : null
                    }
                    inputFormat="DD/MM/YYYY"
                    defaultValue={dayjs(new Date())}
                    sx={{
                      marginBottom: "0px", // Disable the default margin
                    }}
                    disabled={existingNomineeDetails}
                  />
                  {!!errors[index]?.dateOfBirth &&
                    errors[index]?.dateOfBirth && (
                      <span className="error-text">
                        {errors[index]?.dateOfBirth}
                      </span>
                    )}
                </Grid>
                <Grid item xs={xs}>
                  <label className="text-lg text-darkGrey">
                    Share (in percentage) *
                  </label>
                  <FormInput
                    name="percentage"
                    value={nominee.percentage}
                    onChange={(e: any) => handleInputChange(index, e.target)}
                    type="number"
                    placeholder="Enter percentage"
                    onWheel={(e: any) => e.target.blur()}
                    className="mt-1"
                    style={{
                      marginBottom: "-6px", // Disable the default margin
                    }}
                    disabled={existingNomineeDetails}
                  />
                  {!!errors[index]?.percentage && errors[index]?.percentage && (
                    <span className="error-text">
                      {errors[index]?.percentage}
                    </span>
                  )}
                </Grid>
                {nominee.isMinor && ( // Calculate age and check if less than 18
                  <>
                    <Grid item xs={xs}>
                      <label className="text-lg text-darkGrey">
                        Guardian Name *
                      </label>
                      <FormInput
                        name={`nominees[${index}].guardian.name`} // Adjust the name prop
                        value={nominee.guardian?.name || ""}
                        onChange={(e: any) =>
                          handleInputChange(index, {
                            name: "guardianName",
                            value: e.target.value,
                          })
                        }
                         placeholder="Enter guardian name"
                        disabled={existingNomineeDetails}
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                      />
                      {!!errors[index]?.guardianName &&
                        errors[index]?.guardianName && (
                          <span className="error-text">
                            {errors[index]?.guardianName}
                          </span>
                        )}
                    </Grid>
                    <Grid item xs={xs}>
                      <FormSelect
                        label="Guardian Relationship *"
                        name={`nominees[${index}].guardian.relation`} // Adjust the name prop
                        value={nominee.guardian?.relation || ""} // Ensure value is handled correctly
                        onChange={(e: any) =>
                          handleInputChange(index, {
                            name: "guardianRelation",
                            value: e.target.value,
                          })
                        }
                        options={relationshipOptions}
                        className="mt-1"
                        style={{
                          marginBottom: 0, // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                      {!!errors[index]?.guardianRelation &&
                        errors[index]?.guardianRelation && (
                          <span className="error-text">
                            {errors[index]?.guardianRelation}
                          </span>
                        )}
                    </Grid>
                    <Grid item xs={xs}>
                      <label className="text-lg text-darkGrey">
                        Email Address
                      </label>
                      <FormInput
                        name={`nominees[${index}].guardian.email`}
                        value={nominee.guardian?.email || ""}
                        onChange={(e: any) =>
                          handleInputChange(index, {
                            name: "guardianEmail",
                            value: e.target.value,
                          })
                        }
                        placeholder="Enter guardian email"
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>

                    <Grid item xs={xs}>
                      <label className="text-lg text-darkGrey">
                        Mobile Number
                      </label>
                      <FormInput
                        name={`nominees[${index}].guardian.mobile`}
                        value={nominee.guardian?.mobile || ""}
                        onChange={(e: any) =>
                          handleInputChange(index, {
                            name: "guardianMobile",
                            value: e.target.value,
                          })
                        }
                        placeholder="Enter guardian mobile no."
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={() => {
                                newNominees[index].isMinorAddress =
                                  !newNominees[index].isMinorAddress;
                                setNewNominees([...newNominees]);
                              }}
                              checked={newNominees[index].isMinorAddress}
                              disabled={existingNomineeDetails}
                            />
                          }
                          label="Same as nominee address"
                        />
                      </FormGroup>
                    </Grid>
                    <Collapse
                      in={!newNominees[index].isMinorAddress}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid
                        container
                        spacing={0}
                        className="flex justify-between items-center"
                      >
                        <Grid item xs={xs} className="pl-4">
                          <label className="text-lg text-darkGrey">
                            Address 1
                          </label>
                          <FormInput
                            name={`nominees[${index}].guardian.address1`}
                            value={nominee.guardian?.address1 || ""}
                            onChange={(e: any) =>
                              handleInputChange(index, {
                                name: "guardianAddress1",
                                value: e.target.value,
                              })
                            }
                            placeholder="Enter address1"
                            className="mt-1"
                            style={{
                              marginBottom: "-6px", // Disable the default margin
                            }}
                            disabled={existingNomineeDetails}
                          />
                        </Grid>

                        <Grid item xs={xs} className="pl-4">
                          <label className="text-lg text-darkGrey">
                            Address 2
                          </label>
                          <FormInput
                            name={`nominees[${index}].guardian.address2`}
                            value={nominee.guardian?.address2 || ""}
                            onChange={(e: any) =>
                              handleInputChange(index, {
                                name: "guardianAddress2",
                                value: e.target.value,
                              })
                            }
                            placeholder="Enter address2"
                            className="mt-1"
                            style={{
                              marginBottom: "-6px", // Disable the default margin
                            }}
                            disabled={existingNomineeDetails}
                          />
                        </Grid>

                        <Grid item xs={xs} className="pl-4">
                          <label className="text-lg text-darkGrey">
                            Pincode
                          </label>
                          <FormInput
                            name={`nominees[${index}].guardian.pincode`}
                            value={nominee.guardian?.pincode || ""}
                            onChange={(e: any) =>{
                              handleInputChange(index, {
                                name: "guardianPincode",
                                value: e.target.value,
                              });
                              fetchCityAndState("guardian",e.target.value, index);
                            }
                            }
                            placeholder="Enter pincode"
                            className="mt-1"
                            style={{
                              marginBottom: "-6px", // Disable the default margin
                            }}
                            disabled={existingNomineeDetails}
                          />
                        </Grid>

                        <Grid item xs={xs} className="pl-4">
                          <label className="text-lg text-darkGrey">City</label>
                          <FormInput
                            name={`nominees[${index}].guardian.city`}
                            value={nominee.guardian?.city || ""}
                            onChange={(e: any) =>
                              handleInputChange(index, {
                                name: "guardianCity",
                                value: e.target.value,
                              })
                            }
                            placeholder="Enter city"
                            className="mt-1"
                            style={{
                              marginBottom: "-6px", // Disable the default margin
                            }}
                            disabled={existingNomineeDetails}
                          />
                        </Grid>

                        <Grid item xs={xs} className="pl-4">
                          <label className="text-lg text-darkGrey">State</label>
                          <FormInput
                            name={`nominees[${index}].guardian.state`}
                            value={nominee.guardian?.state || ""}
                            onChange={(e: any) =>
                              handleInputChange(index, {
                                name: "guardianState",
                                value: e.target.value,
                              })
                            }
                            placeholder="Enter state"
                            className="mt-1"
                            style={{
                              marginBottom: "-6px", // Disable the default margin
                            }}
                            disabled={existingNomineeDetails}
                          />
                        </Grid>

                        <Grid item xs={xs} className="pl-4">
                          <label className="text-lg text-darkGrey">
                            Country
                          </label>
                          <FormInput
                            name={`nominees[${index}].guardian.country`}
                            value={nominee.guardian?.country || ""}
                            onChange={(e: any) =>
                              handleInputChange(index, {
                                name: "guardianCountry",
                                value: e.target.value,
                              })
                            }
                            placeholder="Enter country"
                            className="mt-1"
                            style={{
                              marginBottom: "-6px", // Disable the default margin
                            }}
                            disabled={existingNomineeDetails}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                    <Grid item xs={xs}>
                      <label className="text-lg text-darkGrey">
                        Guardian Date Of Birth *
                      </label>
                      <FormDatePicker
                        label=""
                        name={`nominees[${index}].guardian.dateOfBirth`}
                        onChange={(date) => {
                          handleInputChange(index, {
                            name: "guardianDateOfBirth",
                            value: date ? date.format("DD/MM/YYYY") : "",
                          });
                        }}
                        value={
                          nominee?.guardian?.dateOfBirth
                            ? dayjs(nominee?.guardian?.dateOfBirth) // Convert the timestamp to a dayjs object
                            : null
                        }
                        inputFormat="DD/MM/YYYY"
                        defaultValue={dayjs(new Date())}
                        sx={{
                          marginBottom: "0px", // Disable the default margin
                        }}
                        disabled={existingNomineeDetails}
                      />
                    </Grid>
                    <Grid item xs={xs}>
                      <label className="text-lg text-darkGrey">
                        Guardian PAN Card Number *
                      </label>
                      <FormInput
                        name={`nominees[${index}].guardian.panCard`} // Adjust the name prop
                        value={nominee.guardian?.pancardNumber || ""}
                        onChange={(e: any) =>
                          handleInputChange(index, {
                            name: "guardianPanCard",
                            value: e.target.value.toUpperCase(),
                          })
                        }
                         placeholder="Enter pan card no."
                        disabled={existingNomineeDetails}
                        className="mt-1"
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}
                      />
                      {!!errors[index]?.guardianPanCard &&
                        errors[index]?.guardianPanCard && (
                          <span className="error-text">
                            {errors[index]?.guardianPanCard}
                          </span>
                        )}
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={xs}
                  className="flex items-center gap-x-4 justify-end"
                >
                  {newNominees && newNominees.length > 0 && submitButton && (
                    <div
                      className="flex justify-center items-center gap-x-2 bg-white p-1 rounded-2xl cursor-pointer"
                      onClick={handleSaveAndProceed}
                    >
                      <DoneIcon
                        sx={{
                          color: colors.white,
                          backgroundColor: colors.tertiary,
                          borderRadius: "40px",
                          width: "2rem",
                          height: "2rem",
                        }}
                      />
                      <span className="text-secondary">Submit</span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Collapse>
            <div className="flex justify-between items-center mt-4"></div>
          </Box>
        ))}
      </div>
      {registeredNominees?.length + newNominees?.length < 3 &&
        registeredNominees?.length + newNominees?.length > 0 &&
        !existingNomineeDetails && (
          <div className="mt-4">
            <span
              className="flex items-center gap-x-2 font-bold underline underline-offset-4 text-secondary cursor-pointer"
              onClick={() => {
                handleAddNominee();
                setOpen(
                  open === registeredNominees?.length + newNominees?.length
                    ? -1
                    : registeredNominees?.length + newNominees?.length
                );
              }}
            >
              <img src={add} alt="add nominee" />
              Add Another Nominee
            </span>
          </div>
        )}
      {!submitButton && newNominees && newNominees.length > 0 && (
        <Button
          type="button"
          className={`form-button`}
          tabIndex={0}
          onClick={handleSaveAndProceed}
        >
          Save and Proceed
        </Button>
      )}

      <ConfirmationModal
        isVisible={isModalVisible} // Render conditionally based on state
        message="Are you sure all fields are correct and verified?"
        onConfirm={handleModalConfirm} // Confirm action and submit form
        onCancel={handleModalCancel} // Cancel action and close modal
      />
    </div>
  );
};

export default AddNominees;
