import * as React from "react";
import { Card, CardContent } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { toCamelCase } from "../../utils/ToCamelCase";

// Define the props for the PieSummary component
interface SimplePieChartProps {
  data: any; // The shape of data can be defined more specifically if needed
}

const PieSummary: React.FC<SimplePieChartProps> = ({ data }) => {
  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));

  const investedFunds = data.investedFunds || []; // Safe fallback to empty array

  // Define a color palette for the pie chart slices
  const colorPalette = [
    "#A8DADC", // Light teal
    "#457B9D", // Steel blue
    "#F4A261", // Peach
    "#264653", // Deep teal
    "#E9C46A", // Muted yellow
    "#A3A3C2", // Soft lavender
    "#BDE0FE", // Pale blue
    "#FFB4A2", // Muted coral
    "#6A994E", // Olive green
    "#D4A373", // Warm tan
    "#D5C6E0", // Lavender gray
    "#83C5BE", // Aqua
    "#F2CC8F", // Beige
    "#81A4CD", // Light periwinkle
    "#B7E4C7", // Mint
    "#ADC178", // Sage green
    "#FFCB77", // Soft orange
    "#E5989B", // Dusty rose
    "#A7BED3", // Powder blue
    "#C3B299", // Taupe
  ];
  

  const totalValue = investedFunds.reduce(
    (acc: number, fund: any) => acc + fund.currentMktValue,
    0
  );

  const minSlicePercentage = 0.01; // Minimum slice size as a percentage of the total value
  const minSliceValue = minSlicePercentage * totalValue;

  // Transform data for pie chart with unique colors
  const chartData = investedFunds.map((fund: any, index: number) => ({
    label: fund.schemeName,
    value: Math.max(fund.currentMktValue, minSliceValue),
    originalValue: fund.currentMktValue,
    color: fund.color || colorPalette[index % colorPalette.length], // Assign unique color
  }));

  function PieCenterLabel() {
    const { width, height, left, top } = useDrawingArea();
    return (
      <g transform={`translate(${left + width / 2}, ${top + height / 2})`}>
        <StyledText x={0} y={-10} fontWeight={"bold"}>
          Total
        </StyledText>
        <StyledText x={0} y={10} color="#A1A1A1">
          {formatIndianCurrency(totalValue.toFixed(2))}
        </StyledText>
      </g>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <Card className="flex-grow flex flex-col">
        <CardContent className="flex-grow flex flex-col">
          <div className="flex-grow flex items-center justify-center">
            {chartData.length > 0 ? (
              <PieChart
                series={[
                  {
                    data: chartData,
                    cx: "50%",
                    cy: "50%",
                    innerRadius: 100,
                    outerRadius: 140,
                    valueFormatter: (value) => "",
                  },
                ]}
                width={400}
                height={400}
                sx={{
                  padding: 0,
                  margin: 0,
                  border: "none",
                }}
                margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                slotProps={{
                  legend: { hidden: true },
                }}
              >
                <PieCenterLabel />
              </PieChart>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <h6 className="font-bold text-xs text-gray-500">
                  No Data Available
                </h6>
              </div>
            )}
          </div>

          {chartData.length > 0 && (
            <div
              className="flex flex-col gap-4 justify-between mt-5"
              style={{
                maxHeight: "200px",
                overflowY: chartData.length > 5 ? "auto" : "hidden",
              }}
            >
              {chartData.map((item: any, index: any) => (
                <div
                  key={index}
                  className="flex justify-between items-center"
                  style={{ width: "100%", color: "#A1A1A1" }}
                >
                  <div className="flex w-full items-center gap-x-3">
                    <span
                      className="w-3 h-3 rounded-3xl"
                      style={{ backgroundColor: item.color }}
                    ></span>
                    <h6 className="font-bold text-xs pr-2">
                      {toCamelCase(item.label)}:
                    </h6>
                  </div>
                  <span className="font-inter text-xs">
                    {formatIndianCurrency(item.originalValue.toFixed(2))}
                  </span>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PieSummary;
