import React, { useState } from 'react';
import { Box, Card, Typography, Divider, IconButton, Chip, CircularProgress, Modal } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch } from 'react-redux';
import { changeOnboardingStatus, downloadAUFForm } from '../../redux/user/userSlice'; // Adjust import according to your structure
import { colors } from '../../constants/colors';
import { useUserData } from '../../hooks/useUserData';
import ConfirmationModal2 from '../modals/ConfirmationModal2';
import authService from '../../redux/auth/authService';
import SuccessfullModal from '../modals/SuccessfullModal';


const BSEOnboarding = ({ users }: any) => {
    const dispatch = useDispatch<any>();
    const user = useUserData();  // Assuming useUserData fetches the user data
    const [ConfirmOpen,setConfirmOpen]=useState(false);
    const [successModal,setSuccessModal]=useState(false);
    const [loadingModal,setLoadingModal]=useState(false);
    const handleConfirm = async () => {
        setConfirmOpen(false);
        setLoadingModal(true);
        try {
            const response = await dispatch(changeOnboardingStatus({status:1}));
            // console.log(response,response.payload,"BSe Onboarding Response");
            if (response.payload.status === 200) {
                authService.logout();
            } else {
                setSuccessModal(true);
            }
        } catch (error) {
            console.error("Error downloading the PDF:", error);
            setSuccessModal(true);
            setLoadingModal(false);
        }
    };
    const handleConfirmClose = () => {
        setConfirmOpen(false);
    }
    const SuccessfulModalClose = () => {
        setSuccessModal(false);
    }
    
    return (
        <>
        <Box className='border border-lightGrey rounded-md'>
            {/* Card Section */}
            <Card
                style={{
                    backgroundColor: colors.lightBg,
                    minHeight: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 16px',
                    borderRadius: '5px',
                }}
                className='h-8 w-full'
            >
                <Typography variant="h5" marginLeft={2} color={colors.darkGrey} fontWeight='bold'>
                    BSE Onboarding
                </Typography>
            </Card>

            {/* New Container Section */}
            <Box className='p-4'>
                {/* Label and Divider */}
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' flexDirection='row'>
                        <Typography variant='h6' color={colors.darkGrey} fontWeight={'bold'} className='mb-2 pr-5'>
                            {(user.registeredByAdmin === 0 && user?.fatcaDone===1)?"You have successfully completed your BSE Onboarding. You are now eligible to start investing.":"Your BSE Onboarding is still pending.Complete it now to unlock full access."}
                        </Typography>
                    </Box>

                    <Box
                        display='flex'
                        alignItems='center'
                        className='rounded-full'
                        style={{
                            padding: '8px',
                            border: `1px solid ${colors.darkGrey}`,
                            backgroundColor: colors.white,
                        }}
                    >
                        <Box
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: user.registeredByAdmin === 0 && user?.fatcaDone===1 ? 'greenyellow' : '#f39a45',
                                borderRadius: '50%',
                                marginRight: '8px',
                            }}
                        />
                        <Typography variant='body2' color={colors.darkGrey}>
                            {user.registeredByAdmin === 0 && user?.fatcaDone===1 ? 'Completed' : 'Pending'}
                        </Typography>
                    </Box>
                </Box>
                {!(user?.registeredByAdmin === 0 && user?.fatcaDone===1) &&(<Divider style={{ marginBottom: '16px', marginTop: '16px' }} />)}

                    <div className="w-full">
                        {!(user?.registeredByAdmin === 0 && user?.fatcaDone===1) && (
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                <Box>
                                <Typography variant='body1' color={colors.darkGrey} className='flex items-center '>
                                    <span style={{ fontWeight: 'normal' }}>Click the button on the right to start your BSE Onboarding.</span>
                                </Typography>
                                </Box>
                                <Box>
                                <button className="text-white bg-primary py-2 px-4 rounded-3xl hover:bg-secondary cursor-pointer" onClick={()=>setConfirmOpen(true)}>
                                Start Onboarding
                                </button>
                                </Box>                                
                            </Box>
                        )}
                    </div>
                </Box>
        </Box>
        <ConfirmationModal2
        isVisible={ConfirmOpen}
        message="Do you want to start your BSE Onboarding?"
        onConfirm={handleConfirm}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal 
      open={successModal}
      message="Server error. Please try again after sometimes!"
      handleClose={SuccessfulModalClose}
      />
      <Modal
          open={loadingModal}
          aria-labelledby="loading-modal-title"
          aria-describedby="loading-modal-description"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div className="w-[30%] h-[30%] flex flex-col items-center justify-center bg-lightBg rounded-lg">
              <Typography
                id="loading-modal-title"
                variant="subtitle1"
                component="h2"
                marginBottom={5}
              >
                {`please wait...`}
              </Typography>
              <CircularProgress />
            </div>
          </Box>
        </Modal>
        </>
    );
};

export default BSEOnboarding;
