import React from 'react';
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa';
import Logo from "../../assets/images/logo.svg";
import MintLogo from '../../assets/images/Mint.png';
import ETLogo from '../../assets/images/ET.png';
import ENLogo from '../../assets/images/EN.jpg';

const Footer = ({ className }: any) => {

  const accessToken=localStorage.getItem("accessToken");

  return (
    <footer className={`bg-bgColor text-gray-800 py-8 ${className}`}>
      {/* Top Section */}
      <div className="max-w-7xl mx-auto px-8 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-8">

      {/* <div className="space-y-4">
        <img src={Logo} alt="AumSampann Logo" className="h-20" />
      </div> */}



        {/* Quick Links */}
        <div className="space-y-4">
          <h3 className="font-bold text-lg">Quick Links</h3>
          <ul className="grid grid-cols-[auto,auto] gap-4">
            <li><a className="hover:text-gray-500">Blogs</a></li> 
            {/* href="https://aumline.in/blog/" */}
            {/* <li><a href="#videos" className="hover:text-gray-500">Videos</a></li> */}
            {/* <li><a href="#press" className="hover:text-gray-500">Press</a></li> */}
            <li><a href="https://www.aumcap.com/static/about-us.aspx" target="_blank" className="hover:text-gray-500">About Us</a></li>
            {/* <li><a href="/privacy" target="_blank" className="hover:text-gray-500">Privacy Policy</a></li> */}
            <li><a href="/disclaimer" target="_blank" className="hover:text-gray-500">Disclaimer</a></li>
            <li><a className="hover:text-gray-500">Terms Of Use</a></li>
            {/* href="/termsOfUse" target="_blank" */}
            {accessToken && (<li><a href="/cutOffTime" target="_blank" className="hover:text-gray-500">Cut-Off Time</a></li>)}
            <li><a href="/tax_reckoner" target="_blank" className="hover:text-gray-500">Tax Reckoner</a></li>
          </ul>
        </div>

        <div className="space-y-4">
          <h3 className="font-bold text-lg">Media</h3>
          <div className="flex space-x-8">
            <img src={MintLogo} alt="Mint" className="h-10 w-11 rounded-full" />
            <img src={ETLogo} alt="ET" className="h-10 w-11 rounded-full" />
            <img src={ENLogo} alt="ET" className="h-10 w-11 rounded-full" />
          </div>
        </div>
      </div>

      <hr className="my-8 border-gray-300" />

      <div className="mt-8  items-center grid grid-cols-1 md:grid-cols-2 px-8 lg:px-8 max-w-7xl mx-auto">
        <h3 className="text-2xl font-semibold">Connect with us</h3>
        <div className="flex flex-col items-start pl-4">
          <p className="text-lg font-medium mb-2">We in Social</p>
          <div className="flex space-x-8">
            <a href="https://www.facebook.com/AUMCap/" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="h-6 w-6 sm:h-8 sm:w-8 text-primary hover:text-gray-500" />
            </a>
            <a href="https://www.linkedin.com/company/AUMCap/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="h-6 w-6 sm:h-8 sm:w-8 text-primary hover:text-gray-500" />
            </a>
            <a href="https://www.instagram.com/aum.cap/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="h-6 w-6 sm:h-8 sm:w-8 text-primary hover:text-gray-500" />
            </a>
            <a href="https://www.youtube.com/channel/UC3VWiH3ALDhUE01e4NmYtCA" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="h-6 w-6 sm:h-8 sm:w-8 text-primary hover:text-gray-500" />
            </a>
            {/* Twitter */}
            <a href="https://x.com/AUMCap" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="h-6 w-6 sm:h-8 sm:w-8 text-primary hover:text-gray-500" />
            </a>
          </div>
        </div>
      </div>



      {/* Bottom Section */}
      <div className="mt-8 text-justify border-t border-gray-300 pt-6">
        <div className="text-sm text-gray-500 mt-6">
        <p className='sm:px-40 px-10'>
          Disclaimer: Neither AUM Capital Market Pvt Ltd nor its affiliates, directors, employees, partners, or agents accept any responsibility or liability for the performance or profitability of the products/services. Further, they do not warrant or guarantee the returns or the achievement of investment objectives at any time or in any manner whatsoever.
There is no assurance or guarantee that the planned goals will be achieved, as the outcomes depend on the investment performance of the products/services.
The information presented in our newsletters and blogs is provided solely for informational purposes and should not be construed as investment advice or recommendations. AUM Capital Market Pvt Ltd ensures full compliance with applicable laws and guidelines issued by SEBI and other regulatory authorities from time to time.
Mutual fund investments are subject to market risks. Please read all scheme-related documents carefully before investing. Past performance is not indicative of future returns. Terms and conditions of the website and its privacy policy are applicable.
No employee, partner, or affiliate of AUM Capital Market Pvt Ltd accepts funds for investments into their personal accounts. Any such activities must be reported immediately.
        </p>
        <p className='sm:px-40 px-10'>
        AUM Capital Market Pvt Ltd :SEBI Single Registration Number : INZ000185431 [ NSE : CM, FO, CD , BSE CM, FO , CD, MCX(COMMODITY), NSE & BSE COMMODITY, Registered Member Code: 12764 (NSE), 3180(BSE), 56505 (MCX). AMFI ARN No. : 64606 - AMFI Registered Mutual Fund Distributors, Depository Participant : NSDL : DP Id: IN304211, CDSL: SEBI Registration no. : IN-DP-CDSL-479-2008 DP Id: 12057800. Research Analyst SEBI Registration no.: INH300002423 CIN: U67120WB2005PTC103417
FOR ANY GRIEVANCE OR INVESTOR COMPLAINT, PLEASE MAIL US AT helpdesk@aumcap.com
Aum Capital Market Pvt. Ltd. is a distributor of Mutual Fund & IPOs. Investment in Securities is subject to market risk.
        </p>
        </div>
        <p className="sm:px-40 text-sm mt-10">Copyrights @ 2024 C AUM Capital Mkt. Pvt. Ltd. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
