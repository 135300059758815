import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../components";
import AddNominees from "../../../components/Profile/AddNominees";
import { useUserData } from "../../../hooks/useUserData";
import AddSingleNominees from "../../../components/Profile/AddSingleNominees";
import NomineeOption from "./NomineeOption";
import { CheckCircle } from "@mui/icons-material";

interface NomineeDetailsProps {
  label: string;
  name: string;
  onNomineeToDemat: () => void;
  allowToProceed: () => void;
  userData: any;
}

const NomineeDetailsForm: React.FC<NomineeDetailsProps> = ({
  label,
  name,
  onNomineeToDemat,
  userData,
}) => {
  const user = useUserData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  

  const handleSaveAndProceed = () => {
    onNomineeToDemat();
  };

  // console.log('user details', nominee);

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl my-8">
        <div className="w-full max-w-[1440px] mx-auto ">
          <div className="">
            {/* <AddSingleNominees
              onSubmit={handleSaveAndProceed}
              Border={false}
              xs={5}
              initialNomineeCount={1}
              removeParent={false}
              nominee={nominee}
            /> */}
            {user && (user?.allowNominee === -1 || user?.allowNominee === 0) ? (
              <>
                <NomineeOption onSubmit={handleSaveAndProceed} />
              </>
            ) : user && user?.nominee?.length > 0 ? (
              <>
                <>
                  <div className="flex flex-col gap-5 items-center">
                    <CheckCircle
                      className="text-green-600 text-3xl"
                      fontSize="large"
                    />
                    <p className="text-lg font-semibold font-inter">
                    Your nominee details have been successfully filled. Please proceed to the next step.
                    </p>
                    <Button
                      type="button"
                      className="form-button"
                      tabIndex={0}
                      onClick={handleSaveAndProceed}
                      //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                      // Disable button if radio is not selected
                    >
                      Proceed
                    </Button>
                  </div>
                </>
              </>
            ) : (
              <>
                <AddNominees
                  onSubmit={handleSaveAndProceed}
                  Border={false}
                  xs={5}
                  initialNomineeCount={1}
                  removeParent={false}
                  nominee={user?.nominee}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NomineeDetailsForm;
